export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Encrypted: any;
  URL: any;
  JSON: any;
  EmailAddress: any;
  DateOnly: any;
  _Any: any;
  federation__FieldSet: any;
  FieldSet: any;
  link__Import: any;
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  calculateEVRebateAmounts: CalculateEvRebateAmountsOutput;
  extractTextFromDocument: ExtractTextFromDocumentOutput;
  /**
   * Retrieve an application by some criteria.
   * Errors: NOT_FOUND
   */
  getApplication: GetApplicationOutput;
  getApplications: GetApplicationsOutput;
  getApplicationSection: ApplicationSection;
  /**
   * Retrieves job request details by some criteria.
   * Errors: NOT_FOUND
   */
  getJob: GetJobOutput;
  getJobs: GetJobsOutput;
  getOperationsUsers: GetOperationsUsersOutput;
  getQuote: GetQuoteOutput;
  getRequest: GetRequestOutput;
  getRequests: GetRequestsOutput;
  getServiceProviderSettings: ServiceProviderSettings;
  getServiceProviderUserInfo: GetServiceProviderUserOutput;
  getServiceProviderUsers: GetServiceProviderUsersOutput;
  isEligibleForConsultation: IsEligibleForConsultationOutput;
};


export type QueryCalculateEvRebateAmountsArgs = {
  input: CalculateEvRebateAmountsInput;
};


export type QueryExtractTextFromDocumentArgs = {
  input: ExtractTextFromDocumentInput;
};


export type QueryGetApplicationArgs = {
  input: GetApplicationInput;
};


export type QueryGetApplicationsArgs = {
  input: GetApplicationsInput;
};


export type QueryGetApplicationSectionArgs = {
  input: GetApplicationSectionInput;
};


export type QueryGetJobArgs = {
  input: GetJobInput;
};


export type QueryGetJobsArgs = {
  input: GetJobsInput;
};


export type QueryGetOperationsUsersArgs = {
  input: GetOperationsUsersInput;
};


export type QueryGetQuoteArgs = {
  input: GetQuoteInput;
};


export type QueryGetRequestArgs = {
  input: GetRequestInput;
};


export type QueryGetRequestsArgs = {
  input: GetRequestsInput;
};


export type QueryGetServiceProviderSettingsArgs = {
  input?: InputMaybe<GetServiceProviderSettingsInput>;
};


export type QueryGetServiceProviderUsersArgs = {
  input?: InputMaybe<GetServiceProviderUsersInput>;
};


export type QueryIsEligibleForConsultationArgs = {
  input: IsEligibleForConsultationInput;
};

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type CalculateEvRebateAmountsInput = {
  applicationId: Scalars['ID'];
  /**
   * installationCost can be left undefined if isInstalledByPgePlus is false.
   * If undefined, we will look up the customers cost on their application. Otherwise, it's a required field.
   */
  installationCost?: InputMaybe<Scalars['Float']>;
  /** for an ER type application, this is false */
  isInstalledByPgePlus: Scalars['Boolean'];
  isPanelReplaced?: InputMaybe<Scalars['Boolean']>;
  isProductSuppliedByCustomer: Scalars['Boolean'];
  /**
   * productCost can be left undefined if isProductSuppliedByCustomer is true.
   * If undefined, we will look up the customers cost on their application. Otherwise, it's a required field.
   */
  productCost?: InputMaybe<Scalars['Float']>;
};

export type CalculateEvRebateAmountsOutput = {
  __typename?: 'CalculateEVRebateAmountsOutput';
  /** the rebate amounts that a customer gets in total */
  customerRebates: CustomerEvRebates;
  /** @deprecated use customerRebates object instead */
  installerGivenRebates: InstallerGivenEvRebates;
  /** how much a customer is owed because of their expenses */
  owedCustomerRebateAmount: Scalars['Float'];
};

export type CustomerEvRebates = {
  __typename?: 'CustomerEVRebates';
  /**
   * The charger and installation rebate as applied to the charger cost, regardless of who (customer or installer)
   * paid for it, and the installation costs, regardless of whether there was a panel installed or not.
   */
  appliedChargerRebateAmount: Scalars['Float'];
  /**
   * Will have a value only if a panel was upgraded. It's the panel rebate amount applied to installation cost.
   * The installation cost covers the charger installation, if there is a cost remaining after 'charger rebate'
   * as well as the panel installation cost.
   */
  appliedPanelRebateAmount: Scalars['Float'];
};

export type InstallerGivenEvRebates = {
  __typename?: 'InstallerGivenEVRebates';
  /** @deprecated use customerRebates.appliedChargerRebateAmount instead */
  chargerRebate: Scalars['Float'];
  /** @deprecated use customerRebates.appliedPanelRebateAmount instead */
  installationRebate: Scalars['Float'];
};

export type ExtractTextFromDocumentInput = {
  documentType: OCRDocumentType;
  /** The name of the fields (1 to many) you want to extract */
  fieldNames: Array<OCRFieldNames>;
  fileKey: Scalars['String'];
  /**
   * The location of the file / the bucket name.
   * Optional, if you don't pass it, we'll assume the default bucket
   */
  fileLocation?: InputMaybe<Scalars['String']>;
};

export enum OcrDocumentType {
  Document = 'DOCUMENT',
  Evcharger = 'EVCHARGER',
  Invoice = 'INVOICE',
  Vin = 'VIN'
}

export enum OcrFieldNames {
  JuiceboxId = 'JuiceboxId',
  ModelNumber = 'ModelNumber',
  SerialNumber = 'SerialNumber',
  Sku = 'SKU',
  Vin = 'vin'
}

export type ExtractTextFromDocumentOutput = {
  __typename?: 'ExtractTextFromDocumentOutput';
  textExtracts: Array<TextExtract>;
};

export type TextExtract = {
  __typename?: 'TextExtract';
  /** The name of the field who's value we want to extract */
  fieldName: Scalars['String'];
  /** The extracted value of the field */
  value?: Maybe<Scalars['String']>;
};

export type GetApplicationInput = {
  /** Application ID or No. */
  applicationId: Scalars['String'];
};

export type GetApplicationOutput = {
  __typename?: 'GetApplicationOutput';
  application?: Maybe<Application>;
};

export type Application = {
  accountId: Scalars['String'];
  applicationNo: Scalars['String'];
  applicationType: ApplicationType;
  createdAt: Scalars['DateTime'];
  encryptedPgePlusAccountId?: Maybe<Scalars['Encrypted']>;
  financialStanding?: Maybe<FinancialStanding>;
  id: Scalars['ID'];
  /** deprecated */
  job?: Maybe<Job>;
  language?: Maybe<Scalars['String']>;
  navHistory?: Maybe<Array<NavHistoryEntry>>;
  pgePlusAccountId?: Maybe<Scalars['String']>;
  premiseId: Scalars['Encrypted'];
  status: ApplicationStatus;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum ApplicationType {
  Er = 'ER',
  Ev = 'EV',
  Hv = 'HV'
}

export type FinancialStanding = {
  __typename?: 'FinancialStanding';
  hasActiveBankruptcy: Scalars['Boolean'];
  hasMissedPGEPlusPayment: Scalars['Boolean'];
  hasPGEPlusDebt: Scalars['Boolean'];
};

export type Job = {
  __typename?: 'Job';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  applicationId: Scalars['ID'];
  applicationNo: Scalars['String'];
  /** @deprecated use payOutOfPocket.charges instead */
  charges?: Maybe<Charges>;
  createdAt: Scalars['DateTime'];
  /** @deprecated use payOutOfPocket.customerPayments instead */
  customerPayments?: Maybe<Array<Payment>>;
  financing?: Maybe<JobFinancing>;
  id: Scalars['ID'];
  isOpened?: Maybe<Scalars['Boolean']>;
  jobDetails?: Maybe<JobDetails>;
  jobLocation: JobLocation;
  /** @deprecated use leadType instead */
  jobType: JobType;
  leadType: LeadType;
  payOutOfPocket?: Maybe<JobPayOutOfPocket>;
  quote?: Maybe<Quote>;
  rebatePayments: Array<RebatePayment>;
  /** @deprecated use payOutOfPocket.refunds instead */
  refunds?: Maybe<Array<Refund>>;
  serviceProviderId: Scalars['ID'];
  serviceProviderInfo: ServiceProviderInfo;
  serviceTime?: Maybe<Interval>;
  status: JobStatus;
  statusNotes?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Charges = {
  __typename?: 'Charges';
  /**
   * amount due at the moment this data is requested.
   * if amountDue is 0, there is no payment needed at the moment this data is requested.
   */
  amountDue: Scalars['Float'];
  /** when the bill got created */
  billDate?: Maybe<Scalars['DateTime']>;
  billId?: Maybe<Scalars['Encrypted']>;
  /** payment due date */
  dueDate?: Maybe<Scalars['DateTime']>;
  isBillGenerated: Scalars['Boolean'];
  /**
   * maximum amount that still needs to be paid. This amount can be higher than amountDue if part of it
   * is not due at the moment this data is requested.
   */
  remainingAmount: Scalars['Float'];
  /** may be the estimate from the installer's quote or the final cost when a bill is ready. */
  totalAmount: Scalars['Float'];
};

export type Payment = {
  __typename?: 'Payment';
  amount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<PaymentCardType>;
  confirmationId?: Maybe<Scalars['String']>;
  lastFourDigitsCardNumber?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export enum PaymentCardType {
  AmazonPay = 'AMAZON_PAY',
  Amex = 'AMEX',
  ApplePay = 'APPLE_PAY',
  Chq = 'CHQ',
  Disc = 'DISC',
  GooglePay = 'GOOGLE_PAY',
  Mc = 'MC',
  PaypalAccount = 'PAYPAL_ACCOUNT',
  PaypalCredit = 'PAYPAL_CREDIT',
  Sav = 'SAV',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export enum PaymentMethod {
  Cc = 'CC',
  Chq = 'CHQ',
  Dc = 'DC',
  Sav = 'SAV'
}

export type JobFinancing = {
  __typename?: 'JobFinancing';
  externalLoanId: Scalars['ID'];
  id: Scalars['ID'];
  lender: Lender;
  loanStatus: LoanStatus;
  minimumPayment?: Maybe<Scalars['Float']>;
  remainingBalance?: Maybe<Scalars['Float']>;
  totalLoanAmount?: Maybe<Scalars['Float']>;
};

export type Lender = {
  __typename?: 'Lender';
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export enum LoanStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Declined = 'DECLINED',
  Referred = 'REFERRED',
  Withdrawn = 'WITHDRAWN'
}

export type JobDetails = {
  __typename?: 'JobDetails';
  comments?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data: JobDetailsData;
  id: Scalars['ID'];
  jobDate: Scalars['DateTime'];
  jobId: Scalars['ID'];
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type JobDetailsData = {
  __typename?: 'JobDetailsData';
  images?: Maybe<Array<ImageItem>>;
  lineItems?: Maybe<Array<LineItem>>;
};

export type ImageItem = {
  __typename?: 'ImageItem';
  imageUrl?: Maybe<Scalars['URL']>;
  key: Scalars['String'];
  name: Scalars['String'];
  objectId: Scalars['String'];
};

export type LineItem = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type JobLocation = {
  __typename?: 'JobLocation';
  addressLine1: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  jobId: Scalars['ID'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  premiseId?: Maybe<Scalars['Encrypted']>;
  state: Scalars['String'];
};

/** @deprecated use LeadType instead */
export enum JobType {
  Ev = 'EV',
  Hvd = 'HVD',
  Hvg = 'HVG'
}

/** A LeadType represents the category of work to be performed. */
export enum LeadType {
  /** EV charger installation */
  Ev = 'EV',
  /** HVAC Ductless installation */
  Hvd = 'HVD',
  /** HVAC General installation */
  Hvg = 'HVG'
}

export type JobPayOutOfPocket = {
  __typename?: 'JobPayOutOfPocket';
  charges?: Maybe<Charges>;
  customerPayments?: Maybe<Array<Payment>>;
  refunds?: Maybe<Array<Refund>>;
};

export type Refund = {
  __typename?: 'Refund';
  amount?: Maybe<Scalars['Float']>;
  refundDate?: Maybe<Scalars['DateTime']>;
  refundMethod?: Maybe<RefundMethod>;
};

export enum RefundMethod {
  Check = 'CHECK',
  Unknown = 'UNKNOWN'
}

export type Quote = {
  createdAt: Scalars['DateTime'];
  data: QuoteData;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isInstallerToCollectPayment: Scalars['Boolean'];
  jobId: Scalars['ID'];
  /** @deprecated use job.serviceTime */
  serviceTime: Interval;
  status: QuoteStatus;
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
};

export type QuoteData = {
  lineItems?: Maybe<Array<LineItem>>;
  options?: Maybe<Array<QuoteOption>>;
  quoteDocument?: Maybe<QuoteDocument>;
};

export type QuoteOption = {
  __typename?: 'QuoteOption';
  lineItems?: Maybe<Array<Maybe<LineItem>>>;
  rank: Scalars['Int'];
  status?: Maybe<QuoteOptionStatus>;
  total: Scalars['Float'];
};

export enum QuoteOptionStatus {
  Accepted = 'ACCEPTED',
  Created = 'CREATED'
}

export type QuoteDocument = {
  __typename?: 'QuoteDocument';
  fileUrl: Scalars['String'];
  name: Scalars['String'];
};

export type Interval = {
  __typename?: 'Interval';
  /** exclusive  */
  end: Scalars['DateTime'];
  /** inclusive */
  start: Scalars['DateTime'];
};

export enum QuoteStatus {
  Accepted = 'ACCEPTED',
  Created = 'CREATED',
  FinancingConfirmed = 'FINANCING_CONFIRMED',
  Rejected = 'REJECTED'
}

export type RebatePayment = {
  __typename?: 'RebatePayment';
  amount: Scalars['Float'];
  status: RebatePaymentStatus;
};

export enum RebatePaymentStatus {
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type ServiceProviderInfo = {
  __typename?: 'ServiceProviderInfo';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export enum JobStatus {
  /** Job was abort by service provider or customer. */
  Aborted = 'ABORTED',
  /** Job was accepted by service provider. */
  Accepted = 'ACCEPTED',
  /** Job was canceled by service provider. There should be a status reason. */
  Canceled = 'CANCELED',
  /** Payment sent to service provider and the job is considered done. */
  Completed = 'COMPLETED',
  /** Job was created for service provider. a.k.a. 'Lead' */
  Created = 'CREATED',
  /** Job was not accepted or rejected within minimal timeframe. */
  Expired = 'EXPIRED',
  /** Quote was accepted by customer and job is now considered in progress. */
  InProgress = 'IN_PROGRESS',
  /** The job was reassigned to another service provider. */
  Reassigned = 'REASSIGNED',
  /** Job was rejected by service provider. */
  Rejected = 'REJECTED',
  /**
   * Service provider has completed the work and submitted job details and final total and
   * now awaiting payment.
   */
  ServiceCompleted = 'SERVICE_COMPLETED'
}

export type NavHistoryEntry = {
  __typename?: 'NavHistoryEntry';
  path: Scalars['String'];
};

export enum ApplicationStatus {
  /** Application was canceled by customer. */
  Canceled = 'CANCELED',
  /** Job was completed. */
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Error = 'ERROR',
  /** Application was not completed within minimal timeframe. */
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  /** Application was rejected as inelligible. */
  Rejected = 'REJECTED',
  /** Service Completed */
  ServiceCompleted = 'SERVICE_COMPLETED',
  /** Associated Job was accepted and customer accepted the related quote. */
  Servicing = 'SERVICING',
  /** successfully submitted */
  Submitted = 'SUBMITTED'
}

export type GetApplicationsInput = {
  accountId?: InputMaybe<Array<Scalars['String']>>;
  /** @deprecated use applicationTypes instead */
  applicationType?: InputMaybe<ApplicationType>;
  applicationTypes?: InputMaybe<Array<ApplicationType>>;
  status?: InputMaybe<Array<ApplicationStatus>>;
};

export type GetApplicationsOutput = {
  __typename?: 'GetApplicationsOutput';
  applications: Array<Application>;
};

export type GetApplicationSectionInput = {
  applicationId: Scalars['String'];
  sectionId: Scalars['String'];
};

export type ApplicationSection = {
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  status: ApplicationSectionStatus;
};

export type ApplicationError = {
  __typename?: 'ApplicationError';
  code: Scalars['String'];
  details?: Maybe<Scalars['JSON']>;
  /** Additional information for debugging */
  message?: Maybe<Scalars['String']>;
};

export enum ApplicationSectionStatus {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Pending = 'PENDING'
}

export type GetJobInput = {
  jobId: Scalars['ID'];
};

export type GetJobOutput = {
  __typename?: 'GetJobOutput';
  application: Application;
  job: Job;
};

export type GetJobsInput = {
  isOpened?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationInput>;
  searchText?: InputMaybe<Scalars['String']>;
  serviceProviderId?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<GetJobsSortInput>;
  status?: InputMaybe<Array<JobStatus>>;
};

export type PaginationInput = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type GetJobsSortInput = {
  applicationNo?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  customerName?: InputMaybe<SortDirection>;
};

/** This matches moonshot schema */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type GetJobsOutput = {
  __typename?: 'GetJobsOutput';
  jobs: Array<Job>;
  pagination: Pagination;
};

/** Differs from PagingParams in moonshot graph */
export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  totalResults: Scalars['Int'];
};

export type GetOperationsUsersInput = {
  operationsUserId?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetOperationsUsersOutput = {
  __typename?: 'GetOperationsUsersOutput';
  operationsUsers?: Maybe<Array<OperationsUser>>;
};

export type OperationsUser = {
  __typename?: 'OperationsUser';
  assignableToCategories: Array<RequestCategory>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum RequestCategory {
  EvConsultation = 'EV_CONSULTATION',
  HvacConsultation = 'HVAC_CONSULTATION',
  Inquiry = 'INQUIRY',
  SolarConsultation = 'SOLAR_CONSULTATION'
}

/** Must be one of quoteId or jobId */
export type GetQuoteInput = {
  jobId?: InputMaybe<Scalars['ID']>;
  quoteId?: InputMaybe<Scalars['ID']>;
};

export type GetQuoteOutput = {
  __typename?: 'GetQuoteOutput';
  quote: Quote;
};

export type GetRequestInput = {
  requestId: Scalars['ID'];
};

export type GetRequestOutput = {
  __typename?: 'GetRequestOutput';
  request: Request;
};

export type Request = {
  /** Account contact information */
  accountContact?: Maybe<Contact>;
  accountId?: Maybe<Scalars['String']>;
  assignedToOperationsUserId?: Maybe<Scalars['String']>;
  /** Assigned operations user's name */
  assignedUserName?: Maybe<Scalars['String']>;
  category: RequestCategory;
  /** Attempts to contact customer */
  contactAttempts?: Maybe<Array<Maybe<ContactAttempts>>>;
  createdAt: Scalars['DateTime'];
  createdByCustomerPersonId?: Maybe<Scalars['String']>;
  createdByOperationsUserId?: Maybe<Scalars['String']>;
  /** Request Due By date */
  dueBy: Scalars['DateTime'];
  id: Scalars['ID'];
  isOpened?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  operatorProvidedDetails: RequestOperatorProvidedDetails;
  premiseId: Scalars['Encrypted'];
  providedDetails: RequestProvidedDetails;
  requestNo: Scalars['String'];
  status: RequestStatus;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Customers contact details */
  userContactDetails: UserContactDetails;
  visibleComments?: Maybe<Array<Maybe<Scalars['String']>>>;
  workComments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Contact = {
  __typename?: 'Contact';
  emailAddress: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ContactAttempts = {
  __typename?: 'ContactAttempts';
  contactDate: Scalars['DateTime'];
  contactMethod: Array<ContactMethod>;
  createdAt: Scalars['DateTime'];
  createdByOperationsUserId: Scalars['String'];
  /** Created by operations user's name */
  createdByOperationsUserName: Scalars['String'];
  id: Scalars['ID'];
  requestId: Scalars['ID'];
  status: ContactStatus;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum ContactMethod {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export enum ContactStatus {
  Canceled = 'CANCELED',
  Successful = 'SUCCESSFUL',
  Unsuccessful = 'UNSUCCESSFUL'
}

export type RequestOperatorProvidedDetails = {
  customerIntentToUsePGEPlus?: Maybe<CustomerPGEPlusUseIntentTimeframe>;
  hasMainElectricalPanelConcerns?: Maybe<Scalars['Boolean']>;
  hasRecommendedInstallReferral?: Maybe<YesNoNAResponse>;
  hasRecommendedMainElectricalPanelUpgrade?: Maybe<YesNoNAResponse>;
  recommendedEnergyTrustRebates?: Maybe<EnergyTrustRebates>;
  recommendedInstallerInformation?: Maybe<RecommendedInstallerInformation>;
};

export enum CustomerPgePlusUseIntentTimeframe {
  NeverCustomerAlreadyHadInstaller = 'NEVER_CUSTOMER_ALREADY_HAD_INSTALLER',
  NeverServiceNotValuable = 'NEVER_SERVICE_NOT_VALUABLE',
  NextSixMonths = 'NEXT_SIX_MONTHS',
  OneYearAndMore = 'ONE_YEAR_AND_MORE',
  SixMonthsToAYear = 'SIX_MONTHS_TO_A_YEAR'
}

export enum YesNoNaResponse {
  No = 'NO',
  NotApplicable = 'NOT_APPLICABLE',
  Yes = 'YES'
}

export enum EnergyTrustRebates {
  CentralAirConditioner = 'CENTRAL_AIR_CONDITIONER',
  DuctedHeatPump = 'DUCTED_HEAT_PUMP',
  DuctlessHeatPump = 'DUCTLESS_HEAT_PUMP',
  NotApplicable = 'NOT_APPLICABLE'
}

export type RecommendedInstallerInformation = {
  __typename?: 'RecommendedInstallerInformation';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type RequestProvidedDetails = {
  otherDiscussion?: Maybe<Scalars['String']>;
  preferredDay?: Maybe<Array<Maybe<PreferredDay>>>;
  preferredTime?: Maybe<Array<Maybe<PreferredTime>>>;
};

export enum PreferredDay {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  NoPreference = 'NO_PREFERENCE',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum PreferredTime {
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Morning = 'MORNING',
  NoPreference = 'NO_PREFERENCE'
}

export enum RequestStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS'
}

export type UserContactDetails = {
  __typename?: 'UserContactDetails';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  relationship?: Maybe<Scalars['String']>;
  requestId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type GetRequestsInput = {
  assignedToOperationsUserId?: InputMaybe<Array<Scalars['String']>>;
  category?: InputMaybe<Array<RequestCategory>>;
  pagination?: InputMaybe<PaginationInput>;
  premiseId?: InputMaybe<Array<Scalars['Encrypted']>>;
  searchText?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<GetRequestsSortInput>;
  status?: InputMaybe<Array<RequestStatus>>;
};

export type GetRequestsSortInput = {
  assignedUserName?: InputMaybe<SortDirection>;
  category?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  customerName?: InputMaybe<SortDirection>;
  requestNo?: InputMaybe<SortDirection>;
};

export type GetRequestsOutput = {
  __typename?: 'GetRequestsOutput';
  pagination: Pagination;
  requests: Array<Request>;
};

export type GetServiceProviderSettingsInput = {
  serviceProviderId?: InputMaybe<Scalars['ID']>;
};

export type ServiceProviderSettings = {
  __typename?: 'ServiceProviderSettings';
  maxUsers: Scalars['Int'];
};

export type GetServiceProviderUserOutput = {
  __typename?: 'GetServiceProviderUserOutput';
  role: ServiceProviderUserRole;
};

export enum ServiceProviderUserRole {
  Admin = 'ADMIN',
  Editor = 'EDITOR'
}

export type GetServiceProviderUsersInput = {
  serviceProviderId?: InputMaybe<Scalars['ID']>;
};

export type GetServiceProviderUsersOutput = {
  __typename?: 'GetServiceProviderUsersOutput';
  users: Array<ServiceProviderUser>;
};

export type ServiceProviderUser = {
  __typename?: 'ServiceProviderUser';
  birthDate?: Maybe<Scalars['DateOnly']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Encrypted'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  role: ServiceProviderUserRole;
};

export type IsEligibleForConsultationInput = {
  category: RequestCategory;
  premiseId: Scalars['Encrypted'];
};

export type IsEligibleForConsultationOutput = {
  __typename?: 'IsEligibleForConsultationOutput';
  /** Reasons the customer is inellgible for submitting a consultaion request. */
  ineligibilityReasons: Array<IneligibilityReasonForConsultation>;
  isEligible: Scalars['Boolean'];
};

export type IneligibilityReasonForConsultation = {
  __typename?: 'IneligibilityReasonForConsultation';
  code: IneligibilityReasonCodeForConsultation;
  message?: Maybe<Scalars['String']>;
};

export enum IneligibilityReasonCodeForConsultation {
  IneligibleRequestLimit = 'INELIGIBLE_REQUEST_LIMIT'
}

export type Mutation = {
  __typename?: 'Mutation';
  abortJob: AbortJobOutput;
  acceptJob: AcceptJobOutput;
  /** customer accepts the quote for EV install */
  acceptQuoteEV: AcceptQuoteEvOutput;
  addServiceProviderUser: AddServiceProviderUserOutput;
  assignOperationUserToRequest: AssignOperationUserToRequestOutput;
  cancelApplication: CancelApplicationOutput;
  cancelJob: CancelJobOutput;
  cancelRequest: CancelRequestOutput;
  completeRequest: CompleteRequestOutput;
  createApplicationER: CreateApplicationErOutput;
  createApplicationEV: CreateApplicationEvOutput;
  createApplicationHV: CreateApplicationHvOutput;
  createCustomerInitiatedSupportRequest: CreateCustomerInitiatedSupportOutput;
  deleteOperationsUser: DeleteOperationsUserOutput;
  deleteServiceProviderUser: DeleteServiceProviderUserOutput;
  insertOperationsUser: InsertOperationsUserOutput;
  makePaymentForJob: PaymentConfirmationList;
  /** Reassign an application to a Job for a different service provider. This can only be called by fallback service provider. */
  reassignJob: ReassignJobOutput;
  /**
   * Creates a new application based on the specified application. The new application will
   * be a copy of the original with a status of CREATED. Only finalized applications may
   * be recreated. These are applications in status REJECTED, CANCELED, EXPIRED.
   * Eligibility checks will be peformed.
   */
  recreateApplication: RecreateApplicationOutput;
  /**
   * Creates a new job for a job that was previously finalized. This will also attempt to recreate
   * the associated application. Eligibility checks will be performed as if it were a new application
   * being created and submitted. The resulting job will be in an ACCEPTED status but no previous
   * job information will be maintained.
   */
  recreateJob: RecreateJobOutput;
  rejectJob: RejectJobOutput;
  /** customer rejects the quote */
  rejectQuote: RejectQuoteOutput;
  /**
   * installer updates target serviceTime on an accepted quote
   * @deprecated use scheduleJob instead
   */
  rescheduleJob: RescheduleJobOutput;
  /**
   * Operates the same as `recreateApplication` but will also attempt to submit the application.
   * Only applications that have been previously submitted may be resubmitted.
   */
  resubmitApplication: ResubmitApplicationOutput;
  /** Set or update job serviceTime. */
  scheduleJob: ScheduleJobOutput;
  submitApplicationER: SubmitApplicationErOutput;
  submitApplicationEV: SubmitApplicationEvOutput;
  submitApplicationHV: SubmitApplicationHvOutput;
  submitEVConsult: SubmitEvConsultOutput;
  submitHVConsult: SubmitHvConsultOutput;
  submitJobDetails: SubmitJobDetailsOutput;
  submitUnsuccessfulContactAttempt: SubmitUnsuccessfulContactAttemptOutput;
  updateBillingContactInfo: UpdateBillingContactInfoOutput;
  updateJob: UpdateJobOutput;
  updateOperationsUser: UpdateOperationsUserOutput;
  updateRequest: UpdateRequestOutput;
  updateSectionERAccountInfo: UpdateSectionErAccountInfoOutput;
  updateSectionERRebateConfirm: UpdateSectionErRebateConfirmOutput;
  updateSectionERRebateInfo: UpdateSectionErRebateInfoOutput;
  updateSectionEVAccountInfo: UpdateSectionEvAccountInfoOutput;
  updateSectionEVChargerSelection: UpdateSectionEvChargerSelectionOutput;
  updateSectionEVEstimate: UpdateSectionEvEstimateOutput;
  updateSectionEVInstall: UpdateSectionEvInstallOutput;
  updateSectionEVPanelInfo: UpdateSectionEvPanelInfoOutput;
  updateSectionEVRebates: UpdateSectionEvRebatesOutput;
  updateSectionEVSummary: UpdateSectionEvSummaryOutput;
  updateSectionHVAccountInfo: UpdateSectionHvAccountInfoOutput;
  updateSectionHVEstimate: UpdateSectionHvEstimateOutput;
  updateSectionHVIncentives: UpdateSectionHvIncentivesOutput;
  updateSectionHVInstall: UpdateSectionHvInstallOutput;
  updateSectionHVSummary: UpdateSectionHvSummaryOutput;
  updateSectionHVSystemInfo: UpdateSectionHvSystemInfoOutput;
  updateSectionHVUploadPhotos: UpdateSectionHvUploadPhotosOutput;
  updateServiceProviderUser: UpdateServiceProviderUserOutput;
  updateUserContactDetails: UpdateUserContactDetailsOutput;
  /** service provider creates or update the quote */
  upsertQuote: UpsertQuoteOutput;
};


export type MutationAbortJobArgs = {
  input: AbortJobInput;
};


export type MutationAcceptJobArgs = {
  input: AcceptJobInput;
};


export type MutationAcceptQuoteEvArgs = {
  input: AcceptQuoteEvInput;
};


export type MutationAddServiceProviderUserArgs = {
  input: AddServiceProviderUserInput;
};


export type MutationAssignOperationUserToRequestArgs = {
  input: AssignOperationUserToRequestInput;
};


export type MutationCancelApplicationArgs = {
  input: CancelApplicationInput;
};


export type MutationCancelJobArgs = {
  input: CancelJobInput;
};


export type MutationCancelRequestArgs = {
  input: CancelRequestInput;
};


export type MutationCompleteRequestArgs = {
  input: CompleteRequestInput;
};


export type MutationCreateApplicationErArgs = {
  input: CreateApplicationErInput;
};


export type MutationCreateApplicationEvArgs = {
  input: CreateApplicationEvInput;
};


export type MutationCreateApplicationHvArgs = {
  input: CreateApplicationHvInput;
};


export type MutationCreateCustomerInitiatedSupportRequestArgs = {
  input: CreateCustomerInitiatedSupportInput;
};


export type MutationDeleteOperationsUserArgs = {
  input: DeleteOperationsUserInput;
};


export type MutationDeleteServiceProviderUserArgs = {
  input: DeleteServiceProviderUserInput;
};


export type MutationInsertOperationsUserArgs = {
  input: InsertOperationsUserInput;
};


export type MutationMakePaymentForJobArgs = {
  input: PaymentInputList;
};


export type MutationReassignJobArgs = {
  input: ReassignJobInput;
};


export type MutationRecreateApplicationArgs = {
  input: RecreateApplicationInput;
};


export type MutationRecreateJobArgs = {
  input: RecreateJobInput;
};


export type MutationRejectJobArgs = {
  input: RejectJobInput;
};


export type MutationRejectQuoteArgs = {
  input: RejectQuoteInput;
};


export type MutationRescheduleJobArgs = {
  input: RescheduleJobInput;
};


export type MutationResubmitApplicationArgs = {
  input: ResubmitApplicationInput;
};


export type MutationScheduleJobArgs = {
  input: ScheduleJobInput;
};


export type MutationSubmitApplicationErArgs = {
  input: SubmitApplicationErInput;
};


export type MutationSubmitApplicationEvArgs = {
  input: SubmitApplicationEvInput;
};


export type MutationSubmitApplicationHvArgs = {
  input: SubmitApplicationHvInput;
};


export type MutationSubmitEvConsultArgs = {
  input: SubmitEvConsultInput;
};


export type MutationSubmitHvConsultArgs = {
  input: SubmitHvConsultInput;
};


export type MutationSubmitJobDetailsArgs = {
  input: SubmitJobDetailsInput;
};


export type MutationSubmitUnsuccessfulContactAttemptArgs = {
  input: SubmitUnsuccessfulContactAttemptInput;
};


export type MutationUpdateBillingContactInfoArgs = {
  input: BillingContactInfoInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateOperationsUserArgs = {
  input: UpdateOperationsUserInput;
};


export type MutationUpdateRequestArgs = {
  input: UpdateRequestInput;
};


export type MutationUpdateSectionErAccountInfoArgs = {
  input: UpdateSectionErAccountInfoInput;
};


export type MutationUpdateSectionErRebateConfirmArgs = {
  input: UpdateSectionErRebateConfirmInput;
};


export type MutationUpdateSectionErRebateInfoArgs = {
  input: UpdateSectionErRebateInfoInput;
};


export type MutationUpdateSectionEvAccountInfoArgs = {
  input: UpdateSectionEvAccountInfoInput;
};


export type MutationUpdateSectionEvChargerSelectionArgs = {
  input: UpdateSectionEvChargerSelectionInput;
};


export type MutationUpdateSectionEvEstimateArgs = {
  input: UpdateSectionEvEstimateInput;
};


export type MutationUpdateSectionEvInstallArgs = {
  input: UpdateSectionEvInstallInput;
};


export type MutationUpdateSectionEvPanelInfoArgs = {
  input: UpdateSectionEvPanelInfoInput;
};


export type MutationUpdateSectionEvRebatesArgs = {
  input: UpdateSectionEvRebatesInput;
};


export type MutationUpdateSectionEvSummaryArgs = {
  input: UpdateSectionEvSummaryInput;
};


export type MutationUpdateSectionHvAccountInfoArgs = {
  input: UpdateSectionHvAccountInfoInput;
};


export type MutationUpdateSectionHvEstimateArgs = {
  input: UpdateSectionHvEstimateInput;
};


export type MutationUpdateSectionHvIncentivesArgs = {
  input: UpdateSectionHvIncentivesInput;
};


export type MutationUpdateSectionHvInstallArgs = {
  input: UpdateSectionHvInstallInput;
};


export type MutationUpdateSectionHvSummaryArgs = {
  input: UpdateSectionHvSummaryInput;
};


export type MutationUpdateSectionHvSystemInfoArgs = {
  input: UpdateSectionHvSystemInfoInput;
};


export type MutationUpdateSectionHvUploadPhotosArgs = {
  input: UpdateSectionHvUploadPhotosInput;
};


export type MutationUpdateServiceProviderUserArgs = {
  input: UpdateServiceProviderUserInput;
};


export type MutationUpdateUserContactDetailsArgs = {
  input: UpdateUserContactDetailsInput;
};


export type MutationUpsertQuoteArgs = {
  input: UpsertQuoteInput;
};

export type AbortJobInput = {
  jobId: Scalars['String'];
  /** Reason for the abort */
  reason: Scalars['String'];
  /** A new service provider to assign the job to, otherwise the next will be selected. */
  reassignToServiceProviderId?: InputMaybe<Scalars['String']>;
  /** Any additional details relating to the reason for aborting. */
  statusNotes?: InputMaybe<Scalars['String']>;
};

export type AbortJobOutput = {
  __typename?: 'AbortJobOutput';
  job: Job;
};

export type AcceptJobInput = {
  jobId: Scalars['String'];
};

export type AcceptJobOutput = {
  __typename?: 'AcceptJobOutput';
  accepted: Scalars['Boolean'];
  job: Job;
};

export type AcceptQuoteEvInput = {
  acknowledgeTerms: Scalars['Boolean'];
  confirmContractorRebatePayee?: InputMaybe<Scalars['Boolean']>;
  confirmEVVehicleOwner: Scalars['Boolean'];
  /** Only required if rebate is available and the customer wants to decline. */
  declineEnrollment?: InputMaybe<Scalars['Boolean']>;
  /** Option index if the customer is choosing a specific quote option to accept. */
  optionRank?: InputMaybe<Scalars['Int']>;
  quoteId: Scalars['ID'];
  version: Scalars['Int'];
};

export type AcceptQuoteEvOutput = {
  __typename?: 'AcceptQuoteEVOutput';
  quote: QuoteEv;
};

export type QuoteEv = Quote & {
  __typename?: 'QuoteEV';
  createdAt: Scalars['DateTime'];
  data: QuoteDataEv;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isInstallerToCollectPayment: Scalars['Boolean'];
  jobId: Scalars['ID'];
  /** @deprecated use job.serviceTime */
  serviceTime: Interval;
  status: QuoteStatus;
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
};

export type QuoteDataEv = QuoteData & {
  __typename?: 'QuoteDataEV';
  /** Provided by the customer */
  acknowledgeTerms?: Maybe<Scalars['Boolean']>;
  /** Provided by the customer */
  confirmContractorRebatePayee?: Maybe<Scalars['Boolean']>;
  /** Provided by the customer */
  confirmEVVehicleOwner?: Maybe<Scalars['Boolean']>;
  /** Provided by the customer */
  declineEnrollment?: Maybe<Scalars['Boolean']>;
  lineItems?: Maybe<Array<LineItem>>;
  options?: Maybe<Array<QuoteOption>>;
  /** Provided by the service provider */
  quoteDocument?: Maybe<QuoteDocument>;
};

export type AddServiceProviderUserInput = {
  birthDate: Scalars['DateOnly'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** format: (999) 999-9999 or 999 999-9999 */
  phoneNumber: Scalars['String'];
  role: ServiceProviderUserRole;
};

export type AddServiceProviderUserOutput = {
  __typename?: 'AddServiceProviderUserOutput';
  /**
   * Reason the business user is not added.
   * The business user will not be added if there is an error.
   */
  errorReason?: Maybe<ErrorReason>;
  user?: Maybe<ServiceProviderUser>;
};

export type ErrorReason = {
  __typename?: 'ErrorReason';
  code: ErrorReasonCode;
  message?: Maybe<Scalars['String']>;
  /** C2M error reference information from c2m */
  reference?: Maybe<ErrorReference>;
};

export enum ErrorReasonCode {
  C2MUserAlreadyExists = 'C2M_USER_ALREADY_EXISTS',
  EmailDomainMismatch = 'EMAIL_DOMAIN_MISMATCH',
  FirebaseEmailAlreadyExists = 'FIREBASE_EMAIL_ALREADY_EXISTS',
  UnidentifiedError = 'UNIDENTIFIED_ERROR'
}

export type ErrorReference = {
  __typename?: 'ErrorReference';
  category?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
};

export type AssignOperationUserToRequestInput = {
  operationUserEmail: Scalars['EmailAddress'];
  requestNo: Scalars['String'];
};

export type AssignOperationUserToRequestOutput = {
  __typename?: 'AssignOperationUserToRequestOutput';
  request: Request;
};

export type CancelApplicationInput = {
  /** Application ID or No. */
  applicationId: Scalars['String'];
  reason: Scalars['String'];
};

export type CancelApplicationOutput = {
  __typename?: 'CancelApplicationOutput';
  canceled: Scalars['Boolean'];
};

export type CancelJobInput = {
  jobId: Scalars['ID'];
  reason: Scalars['String'];
  statusNotes?: InputMaybe<Scalars['String']>;
};

export type CancelJobOutput = {
  __typename?: 'CancelJobOutput';
  canceled: Scalars['Boolean'];
  job: Job;
};

export type CancelRequestInput = {
  contactDate: Scalars['DateTime'];
  contactMethod: Array<ContactMethod>;
  reasonOfCancellation: Scalars['String'];
  requestNo: Scalars['String'];
};

export type CancelRequestOutput = {
  __typename?: 'CancelRequestOutput';
  canceled: Scalars['Boolean'];
  request: Request;
};

export type CompleteRequestInput = {
  contactDate: Scalars['DateTime'];
  contactMethod: Array<ContactMethod>;
  operatorProvidedDetails?: InputMaybe<OperatorProvidedDetailsInput>;
  requestNo: Scalars['String'];
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  visibleComment?: InputMaybe<Scalars['String']>;
  workComment?: InputMaybe<Scalars['String']>;
};

export type OperatorProvidedDetailsInput = {
  customerIntentToUsePGEPlus?: InputMaybe<CustomerPGEPlusUseIntentTimeframe>;
  hasMainElectricalPanelConcerns?: InputMaybe<Scalars['Boolean']>;
  hasRecommendedInstallReferral?: InputMaybe<YesNoNAResponse>;
  hasRecommendedMainElectricalPanelUpgrade?: InputMaybe<YesNoNAResponse>;
  recommendedEnergyTrustRebates?: InputMaybe<EnergyTrustRebates>;
  /** recommendedInstallerInformation is mandatory if operations user select Yes for the question Recommneding installer referral */
  recommendedInstallerInformation?: InputMaybe<RecommendedInstallerInformationInput>;
};

export type RecommendedInstallerInformationInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CompleteRequestOutput = {
  __typename?: 'CompleteRequestOutput';
  completed: Scalars['Boolean'];
  request: Request;
};

export type CreateApplicationErInput = {
  accountId: Scalars['ID'];
  /** customer's preferred language locale */
  language?: InputMaybe<Scalars['String']>;
  personId: Scalars['Encrypted'];
  premiseId: Scalars['Encrypted'];
};

export type CreateApplicationErOutput = {
  __typename?: 'CreateApplicationEROutput';
  /** The created application if the customer is eligible to participate in the ER program. */
  application?: Maybe<ApplicationEr>;
  /**
   * Reasons the customer is inellgible for creating an ER application.
   * The application will not be created if the customer is ineligible.
   */
  ineligibilityReasons: Array<IneligibilityReason>;
};

export type ApplicationEr = Application & {
  __typename?: 'ApplicationER';
  accountId: Scalars['String'];
  applicationNo: Scalars['String'];
  applicationType: ApplicationType;
  createdAt: Scalars['DateTime'];
  details: Array<SectionEr>;
  /** an ER application will never have a encryptedPgePlusAccountId */
  encryptedPgePlusAccountId?: Maybe<Scalars['Encrypted']>;
  financialStanding?: Maybe<FinancialStanding>;
  id: Scalars['ID'];
  /** @deprecated */
  job?: Maybe<Job>;
  language?: Maybe<Scalars['String']>;
  navHistory?: Maybe<Array<NavHistoryEntry>>;
  /** an ER application will never have a pgePlusAccountId */
  pgePlusAccountId?: Maybe<Scalars['String']>;
  premiseId: Scalars['Encrypted'];
  status: ApplicationStatus;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SectionEr = {
  id: Scalars['ID'];
  sectionType: ApplicationERSectionType;
};

export enum ApplicationErSectionType {
  ErAccountInfo = 'ER_ACCOUNT_INFO',
  ErRebateConfirm = 'ER_REBATE_CONFIRM',
  ErRebateInfo = 'ER_REBATE_INFO'
}

export type IneligibilityReason = {
  __typename?: 'IneligibilityReason';
  code: IneligibilityReasonCode;
  /** Additional details */
  details?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  /** C2M error reference information from c2m */
  reference?: Maybe<ErrorReference>;
};

export enum IneligibilityReasonCode {
  /** An application is already in progress for the associated premise */
  IneligibleApplicationInProgress = 'INELIGIBLE_APPLICATION_IN_PROGRESS',
  IneligibleApplicationLimit = 'INELIGIBLE_APPLICATION_LIMIT',
  IneligibleC2M = 'INELIGIBLE_C2M',
  IneligibleHomeType = 'INELIGIBLE_HOME_TYPE',
  /** Especially for rebate only flow */
  IneligibleMaxRebates = 'INELIGIBLE_MAX_REBATES',
  IneligibleOwnerType = 'INELIGIBLE_OWNER_TYPE',
  IneligibleProductType = 'INELIGIBLE_PRODUCT_TYPE',
  IneligibleServiceProviderUnavailble = 'INELIGIBLE_SERVICE_PROVIDER_UNAVAILBLE'
}

export type CreateApplicationEvInput = {
  accountId: Scalars['ID'];
  /** customer's preferred language locale */
  language?: InputMaybe<Scalars['String']>;
  personId: Scalars['Encrypted'];
  premiseId: Scalars['Encrypted'];
  /** whether or not the charger will be supplied by the customer */
  suppliedByCustomer: Scalars['Boolean'];
};

export type CreateApplicationEvOutput = {
  __typename?: 'CreateApplicationEVOutput';
  /** The created application if the customer is eligible to participate in the EV program. */
  application?: Maybe<ApplicationEv>;
  /**
   * Reasons the customer is inellgible for creating an EV application.
   * The application will not be created if the customer is ineligible.
   */
  ineligibilityReasons: Array<IneligibilityReason>;
};

export type ApplicationEv = Application & {
  __typename?: 'ApplicationEV';
  accountId: Scalars['String'];
  applicationNo: Scalars['String'];
  applicationType: ApplicationType;
  createdAt: Scalars['DateTime'];
  details: Array<SectionEv>;
  encryptedPgePlusAccountId?: Maybe<Scalars['Encrypted']>;
  financialStanding?: Maybe<FinancialStanding>;
  id: Scalars['ID'];
  job?: Maybe<Job>;
  language?: Maybe<Scalars['String']>;
  navHistory?: Maybe<Array<NavHistoryEntry>>;
  pgePlusAccountId?: Maybe<Scalars['String']>;
  premiseId: Scalars['Encrypted'];
  status: ApplicationStatus;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SectionEv = {
  id: Scalars['ID'];
  sectionType: ApplicationEVSectionType;
};

export enum ApplicationEvSectionType {
  EvAccountInfo = 'EV_ACCOUNT_INFO',
  EvChargerSelection = 'EV_CHARGER_SELECTION',
  EvEstimate = 'EV_ESTIMATE',
  EvInstall = 'EV_INSTALL',
  EvPanelInfo = 'EV_PANEL_INFO',
  EvRebates = 'EV_REBATES',
  EvSummary = 'EV_SUMMARY'
}

export type CreateApplicationHvInput = {
  accountId: Scalars['ID'];
  /** customer's preferred language locale */
  language?: InputMaybe<Scalars['String']>;
  personId: Scalars['Encrypted'];
  premiseId: Scalars['Encrypted'];
};

export type CreateApplicationHvOutput = {
  __typename?: 'CreateApplicationHVOutput';
  /** The created application if the customer is eligible to participate in the EV program. */
  application?: Maybe<ApplicationHv>;
  /**
   * Reasons the customer is ineligible for creating an EV application.
   * The application will not be created if the customer is ineligible.
   */
  ineligibilityReasons: Array<IneligibilityReason>;
};

export type ApplicationHv = Application & {
  __typename?: 'ApplicationHV';
  accountId: Scalars['String'];
  applicationNo: Scalars['String'];
  applicationType: ApplicationType;
  createdAt: Scalars['DateTime'];
  details: Array<SectionHv>;
  encryptedPgePlusAccountId?: Maybe<Scalars['Encrypted']>;
  financialStanding?: Maybe<FinancialStanding>;
  id: Scalars['ID'];
  job?: Maybe<Job>;
  language?: Maybe<Scalars['String']>;
  navHistory?: Maybe<Array<NavHistoryEntry>>;
  pgePlusAccountId?: Maybe<Scalars['String']>;
  premiseId: Scalars['Encrypted'];
  status: ApplicationStatus;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SectionHv = {
  id: Scalars['ID'];
  sectionType: ApplicationHVSectionType;
};

export enum ApplicationHvSectionType {
  HvAccountInfo = 'HV_ACCOUNT_INFO',
  HvEstimate = 'HV_ESTIMATE',
  HvIncentives = 'HV_INCENTIVES',
  HvInstall = 'HV_INSTALL',
  HvSummary = 'HV_SUMMARY',
  HvSystemInfo = 'HV_SYSTEM_INFO',
  HvUploadPhotos = 'HV_UPLOAD_PHOTOS'
}

export type CreateCustomerInitiatedSupportInput = {
  alternateEmail?: InputMaybe<Scalars['EmailAddress']>;
  applicationId: Scalars['String'];
  comments: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  reason: Scalars['String'];
};

export type CreateCustomerInitiatedSupportOutput = {
  __typename?: 'CreateCustomerInitiatedSupportOutput';
  success: Scalars['Boolean'];
};

export type DeleteOperationsUserInput = {
  operationsUserId: Scalars['ID'];
};

export type DeleteOperationsUserOutput = {
  __typename?: 'DeleteOperationsUserOutput';
  isSuccess: Scalars['Boolean'];
};

export type DeleteServiceProviderUserInput = {
  id: Scalars['Encrypted'];
};

export type DeleteServiceProviderUserOutput = {
  __typename?: 'DeleteServiceProviderUserOutput';
  deleted: Scalars['Boolean'];
};

export type InsertOperationsUserInput = {
  assignableToCategories: Array<RequestCategory>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type InsertOperationsUserOutput = {
  __typename?: 'InsertOperationsUserOutput';
  operationsUser?: Maybe<OperationsUser>;
};

export type PaymentInputList = {
  applicationId: Scalars['ID'];
  payments: Array<PaymentInput>;
};

export type PaymentInput = {
  paymentAmount: Scalars['Float'];
  paymentDate: Scalars['DateTime'];
  personId: Scalars['Encrypted'];
  tokenId: Scalars['String'];
};

export type PaymentConfirmationList = {
  __typename?: 'PaymentConfirmationList';
  paymentConfirmation: Array<PaymentConfirmation>;
};

export type PaymentConfirmation = {
  __typename?: 'PaymentConfirmation';
  accountId: Scalars['String'];
  cardType: PaymentCardType;
  /** confirmationId is Paymentus reference number for successful payments */
  confirmationId?: Maybe<Scalars['String']>;
  digitalWalletEmail?: Maybe<Scalars['String']>;
  errorCode?: Maybe<PaymentErrorCode>;
  isSuccess: Scalars['Boolean'];
  lastFourDigitsCardNumber: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentMethod: PaymentMethod;
  paymentStatus?: Maybe<PaymentStatus>;
};

export enum PaymentErrorCode {
  AddressVerificationCardSecurityCodeFailed = 'ADDRESS_VERIFICATION_CARD_SECURITY_CODE_FAILED',
  AddressVerificationFailed = 'ADDRESS_VERIFICATION_FAILED',
  AlreadyReversed = 'ALREADY_REVERSED',
  AuthorizationExpired = 'AUTHORIZATION_EXPIRED',
  AuthorizationNotFound = 'AUTHORIZATION_NOT_FOUND',
  Callvoicecenter = 'CALLVOICECENTER',
  CardAccountLengthError = 'CARD_ACCOUNT_LENGTH_ERROR',
  CardIssuerDeclinedCvv = 'CARD_ISSUER_DECLINED_CVV',
  CardLostStolen = 'CARD_LOST_STOLEN',
  CardTypeNotEnabled = 'CARD_TYPE_NOT_ENABLED',
  CardholderAuthenticationRequired = 'CARDHOLDER_AUTHENTICATION_REQUIRED',
  CardholderStoppedAllBilling = 'CARDHOLDER_STOPPED_ALL_BILLING',
  CardholderStoppedBilling = 'CARDHOLDER_STOPPED_BILLING',
  CardholdersActivityLimitExceeded = 'CARDHOLDERS_ACTIVITY_LIMIT_EXCEEDED',
  Cardisexpired = 'CARDISEXPIRED',
  Connectiontimeout = 'CONNECTIONTIMEOUT',
  CreditCardNumberNotMatch = 'CREDIT_CARD_NUMBER_NOT_MATCH',
  CurrencyMustMatchPaypalAccount = 'CURRENCY_MUST_MATCH_PAYPAL_ACCOUNT',
  CurrencyNotEnabledPaypalSeller = 'CURRENCY_NOT_ENABLED_PAYPAL_SELLER',
  Decline = 'DECLINE',
  DeclinedCallForApproval = 'DECLINED_CALL_FOR_APPROVAL',
  DeclinedCallIssuer = 'DECLINED_CALL_ISSUER',
  DeclinedUpdatedCardholderAvailable = 'DECLINED_UPDATED_CARDHOLDER_AVAILABLE',
  Defaultcall = 'DEFAULTCALL',
  Donothonor = 'DONOTHONOR',
  DuplicateTransaction = 'DUPLICATE_TRANSACTION',
  Duplicatepayment = 'DUPLICATEPAYMENT',
  EncryptionError = 'ENCRYPTION_ERROR',
  ErrorDoNotRetryCallIssuer = 'ERROR_DO_NOT_RETRY_CALL_ISSUER',
  Expirydatepast = 'EXPIRYDATEPAST',
  FraudSecurityViolation = 'FRAUD_SECURITY_VIOLATION',
  Greaterthanmaxamount = 'GREATERTHANMAXAMOUNT',
  InconsistentData = 'INCONSISTENT_DATA',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InvalidAuthorizationCode = 'INVALID_AUTHORIZATION_CODE',
  InvalidCc = 'INVALID_CC',
  InvalidClientId = 'INVALID_CLIENT_ID',
  InvalidCreditPlan = 'INVALID_CREDIT_PLAN',
  InvalidCurrencyCode = 'INVALID_CURRENCY_CODE',
  InvalidMerchant = 'INVALID_MERCHANT',
  InvalidMerchantNumber = 'INVALID_MERCHANT_NUMBER',
  InvalidSecurePaymentData = 'INVALID_SECURE_PAYMENT_DATA',
  InvalidStore = 'INVALID_STORE',
  InvalidTaxAmount = 'INVALID_TAX_AMOUNT',
  InvalidTransactionData = 'INVALID_TRANSACTION_DATA',
  InvalidTransactionDivisionNumber = 'INVALID_TRANSACTION_DIVISION_NUMBER',
  InvalidUserCredentials = 'INVALID_USER_CREDENTIALS',
  Invalidamount = 'INVALIDAMOUNT',
  Invalidccnumber = 'INVALIDCCNUMBER',
  Invalideffective = 'INVALIDEFFECTIVE',
  Invalidexpiration = 'INVALIDEXPIRATION',
  Invalidtransaction = 'INVALIDTRANSACTION',
  IssuerCardholderPutRestriction = 'ISSUER_CARDHOLDER_PUT_RESTRICTION',
  Issuerunavailable = 'ISSUERUNAVAILABLE',
  Lessthanminamount = 'LESSTHANMINAMOUNT',
  LimitExceeded = 'LIMIT_EXCEEDED',
  Lost = 'LOST',
  Loststolen = 'LOSTSTOLEN',
  NoAccount = 'NO_ACCOUNT',
  NoActionTaken = 'NO_ACTION_TAKEN',
  NoDataFound = 'NO_DATA_FOUND',
  Noanswer = 'NOANSWER',
  Notonfile = 'NOTONFILE',
  OfflineIssuerDeclined = 'OFFLINE_ISSUER_DECLINED',
  PartialApprovalGroupIiiVersion = 'PARTIAL_APPROVAL_GROUP_III_VERSION',
  PayerCannotPayWithPaypal = 'PAYER_CANNOT_PAY_WITH_PAYPAL',
  PaypalAccountFundingInstrumentDeclined = 'PAYPAL_ACCOUNT_FUNDING_INSTRUMENT_DECLINED',
  PaypalAccountLockedClosed = 'PAYPAL_ACCOUNT_LOCKED_CLOSED',
  PaypalAuthorizationAmountLimitExceeded = 'PAYPAL_AUTHORIZATION_AMOUNT_LIMIT_EXCEEDED',
  PaypalAuthorizationCountLimitExceeded = 'PAYPAL_AUTHORIZATION_COUNT_LIMIT_EXCEEDED',
  PaypalBlockingDuplicateOrderIds = 'PAYPAL_BLOCKING_DUPLICATE_ORDER_IDS',
  PaypalBusinessAccountLockedClosed = 'PAYPAL_BUSINESS_ACCOUNT_LOCKED_CLOSED',
  PaypalBusinessAccountPreferenceTransactionFailing = 'PAYPAL_BUSINESS_ACCOUNT_PREFERENCE_TRANSACTION_FAILING',
  PaypalBusinessAccountRestricted = 'PAYPAL_BUSINESS_ACCOUNT_RESTRICTED',
  PaypalBuyerRevokedAuthorization = 'PAYPAL_BUYER_REVOKED_AUTHORIZATION',
  PaypalChannelInitiatedBillingNotEnabled = 'PAYPAL_CHANNEL_INITIATED_BILLING_NOT_ENABLED',
  PaypalDomesticTransactionRequired = 'PAYPAL_DOMESTIC_TRANSACTION_REQUIRED',
  PaypalMerchantAccountConfigurationError = 'PAYPAL_MERCHANT_ACCOUNT_CONFIGURATION_ERROR',
  PaypalPayeeAccountInvalidNoConfirmedEmail = 'PAYPAL_PAYEE_ACCOUNT_INVALID_NO_CONFIRMED_EMAIL',
  PaypalPayeeBlockedTransaction = 'PAYPAL_PAYEE_BLOCKED_TRANSACTION',
  PaypalPayeeEmailIncorrectlyFormatted = 'PAYPAL_PAYEE_EMAIL_INCORRECTLY_FORMATTED',
  PaypalPayeeEmailPermissionDenied = 'PAYPAL_PAYEE_EMAIL_PERMISSION_DENIED',
  PaypalPaymentAlreadyCompleted = 'PAYPAL_PAYMENT_ALREADY_COMPLETED',
  PaypalPaymentMethodInvalid = 'PAYPAL_PAYMENT_METHOD_INVALID',
  PaypalPendingPaymentsNotSupported = 'PAYPAL_PENDING_PAYMENTS_NOT_SUPPORTED',
  PaypalPhoneNumberRequired = 'PAYPAL_PHONE_NUMBER_REQUIRED',
  PaypalReferenceTransactionsNotEnabled = 'PAYPAL_REFERENCE_TRANSACTIONS_NOT_ENABLED',
  PaypalTaxInfoRequired = 'PAYPAL_TAX_INFO_REQUIRED',
  PaypalTransactionLimitExceeded = 'PAYPAL_TRANSACTION_LIMIT_EXCEEDED',
  PaypalValidationError = 'PAYPAL_VALIDATION_ERROR',
  Pickup = 'PICKUP',
  ProcessorDeclined = 'PROCESSOR_DECLINED',
  ProcessorDoesNotSupportFeature = 'PROCESSOR_DOES_NOT_SUPPORT_FEATURE',
  Processordecline = 'PROCESSORDECLINE',
  RefusedDueToPaypalRiskModel = 'REFUSED_DUE_TO_PAYPAL_RISK_MODEL',
  SetupErrorAmount = 'SETUP_ERROR_AMOUNT',
  SetupErrorCard = 'SETUP_ERROR_CARD',
  SetupErrorHierarchy = 'SETUP_ERROR_HIERARCHY',
  SetupErrorMerchant = 'SETUP_ERROR_MERCHANT',
  SetupErrorTerminal = 'SETUP_ERROR_TERMINAL',
  Stolen = 'STOLEN',
  SurchargeNotPermitted = 'SURCHARGE_NOT_PERMITTED',
  TransactionAmountExceedsDivisionLimit = 'TRANSACTION_AMOUNT_EXCEEDS_DIVISION_LIMIT',
  TransactionNotAllowed = 'TRANSACTION_NOT_ALLOWED',
  Unknown = 'UNKNOWN',
  Violation = 'VIOLATION',
  VoiceAuthorizationRequired = 'VOICE_AUTHORIZATION_REQUIRED'
}

export enum PaymentStatus {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Failed = 'FAILED',
  Scheduled = 'SCHEDULED',
  Unknown = 'UNKNOWN'
}

export type ReassignJobInput = {
  /** ID of the service provider to assign a job to */
  assigneeServiceProviderId: Scalars['ID'];
  /** The job currently owned by the fallback service provider. This should be in a CREATED or EXPIRED status. */
  jobId: Scalars['String'];
};

export type ReassignJobOutput = {
  __typename?: 'ReassignJobOutput';
  job: Job;
};

export type RecreateApplicationInput = {
  applicationId: Scalars['ID'];
  /**
   * If specified, when submitting the new application, the system will attempt
   * to assign the job to the given service provider. This may or may not be honored
   * based on changes to the application the customer may make and other factors.
   */
  targetServiceProviderId?: InputMaybe<Scalars['ID']>;
};

export type RecreateApplicationOutput = {
  __typename?: 'RecreateApplicationOutput';
  /** The newly created application. */
  application?: Maybe<Application>;
  ineligibilityReasons: Array<IneligibilityReason>;
};

export type RecreateJobInput = {
  jobId: Scalars['ID'];
};

export type RecreateJobOutput = {
  __typename?: 'RecreateJobOutput';
  application?: Maybe<Application>;
  ineligibilityReasons: Array<IneligibilityReason>;
  job?: Maybe<Job>;
};

export type RejectJobInput = {
  jobId: Scalars['String'];
};

export type RejectJobOutput = {
  __typename?: 'RejectJobOutput';
  job: Job;
  rejected: Scalars['Boolean'];
};

export type RejectQuoteInput = {
  quoteId: Scalars['ID'];
  version: Scalars['Int'];
};

export type RejectQuoteOutput = {
  __typename?: 'RejectQuoteOutput';
  quote: Quote;
};

/** @deprecated use scheduleJob mutation instead */
export type RescheduleJobInput = {
  jobId: Scalars['String'];
  serviceTime: IntervalInput;
};

export type IntervalInput = {
  /** exclusive  */
  end: Scalars['DateTime'];
  /** inclusive */
  start: Scalars['DateTime'];
};

/** @deprecated use scheduleJob mutation instead */
export type RescheduleJobOutput = {
  __typename?: 'RescheduleJobOutput';
  quote: Quote;
};

export type ResubmitApplicationInput = {
  applicationId: Scalars['ID'];
  /**
   * If specified, when submitting the new application, the system will attempt
   * to assign the job to the given service provider.
   */
  targetServiceProviderId?: InputMaybe<Scalars['ID']>;
};

export type ResubmitApplicationOutput = {
  __typename?: 'ResubmitApplicationOutput';
  /** The newly created application. */
  application?: Maybe<Application>;
  /**
   * If populated, the application was unable to be sumitted due to ineligibility
   * and will be put in a REJECTED status.
   */
  ineligibilityReasons: Array<IneligibilityReason>;
};

export type ScheduleJobInput = {
  jobId: Scalars['String'];
  serviceTime: IntervalInput;
};

export type ScheduleJobOutput = {
  __typename?: 'ScheduleJobOutput';
  job: Job;
};

export type SubmitApplicationErInput = {
  /** Application ID or No. */
  applicationId: Scalars['ID'];
};

export type SubmitApplicationErOutput = {
  __typename?: 'SubmitApplicationEROutput';
  application: ApplicationEr;
  /**
   * Reasons the customer is inellgible for submitting an ER application.
   * The application will not be submitted if the customer is ineligible.
   */
  ineligibilityReasons: Array<IneligibilityReason>;
};

export type SubmitApplicationEvInput = {
  /** Application ID or No. */
  applicationId: Scalars['ID'];
};

export type SubmitApplicationEvOutput = {
  __typename?: 'SubmitApplicationEVOutput';
  application: ApplicationEv;
  /**
   * Reasons the customer is inellgible for submitting an EV application.
   * The application will not be submitted if the customer is ineligible.
   */
  ineligibilityReasons: Array<IneligibilityReason>;
};

export type SubmitApplicationHvInput = {
  applicationId: Scalars['ID'];
};

export type SubmitApplicationHvOutput = {
  __typename?: 'SubmitApplicationHVOutput';
  application?: Maybe<ApplicationHv>;
  ineligibilityReasons: Array<IneligibilityReason>;
};

export type SubmitEvConsultInput = {
  /** the P&L account id */
  accountId?: InputMaybe<Scalars['ID']>;
  discussionTopics: Array<EVConsultDiscussionTopic>;
  isMainContact?: InputMaybe<Scalars['Boolean']>;
  otherContactInfo?: InputMaybe<OtherContactInfo>;
  otherDiscussion?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['Encrypted']>;
  preferredDay?: InputMaybe<Array<PreferredDay>>;
  preferredLanguage: Scalars['String'];
  preferredTime?: InputMaybe<Array<PreferredTime>>;
  premiseId: Scalars['Encrypted'];
};

export enum EvConsultDiscussionTopic {
  Installation = 'INSTALLATION',
  LevelOneVsLevelTwo = 'LEVEL_ONE_VS_LEVEL_TWO',
  Other = 'OTHER',
  PanelUpgrade = 'PANEL_UPGRADE',
  PgePlus = 'PGE_PLUS',
  ProductSelection = 'PRODUCT_SELECTION',
  SmartChargingProgram = 'SMART_CHARGING_PROGRAM',
  TimeOfDay = 'TIME_OF_DAY'
}

export type OtherContactInfo = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  relationship?: InputMaybe<Scalars['String']>;
};

export type SubmitEvConsultOutput = {
  __typename?: 'SubmitEVConsultOutput';
  isSuccess: Scalars['Boolean'];
};

export type SubmitHvConsultInput = {
  /** the P&L account id */
  accountId?: InputMaybe<Scalars['ID']>;
  consultationService: HVConsultationService;
  discussionTopics: Array<HVConsultDiscussionTopic>;
  isMainContact?: InputMaybe<Scalars['Boolean']>;
  otherContactInfo?: InputMaybe<OtherContactInfo>;
  otherDiscussion?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['Encrypted']>;
  preferredDay?: InputMaybe<Array<PreferredDay>>;
  preferredLanguage: Scalars['String'];
  preferredTime?: InputMaybe<Array<PreferredTime>>;
  premiseId: Scalars['Encrypted'];
};

export enum HvConsultationService {
  Both = 'BOTH',
  Cooling = 'COOLING',
  Heating = 'HEATING'
}

export enum HvConsultDiscussionTopic {
  AllergenAndVirusFiltration = 'ALLERGEN_AND_VIRUS_FILTRATION',
  BasicOperation = 'BASIC_OPERATION',
  EnergySavings = 'ENERGY_SAVINGS',
  HumidityControl = 'HUMIDITY_CONTROL',
  Other = 'OTHER',
  PeakTimeRebates = 'PEAK_TIME_REBATES',
  PgePlus = 'PGE_PLUS',
  SmartThermostatProgram = 'SMART_THERMOSTAT_PROGRAM',
  SoundLevel = 'SOUND_LEVEL'
}

export type SubmitHvConsultOutput = {
  __typename?: 'SubmitHVConsultOutput';
  isSuccess: Scalars['Boolean'];
};

export type SubmitJobDetailsInput = {
  jobDetails: JobDetailsInput;
};

export type JobDetailsInput = {
  comments?: InputMaybe<Scalars['String']>;
  data: JobDetailsDataInput;
  jobDate: Scalars['DateTime'];
  jobId: Scalars['ID'];
  total: Scalars['Float'];
};

export type JobDetailsDataInput = {
  images?: InputMaybe<Array<ImageItemInput>>;
  lineItems?: InputMaybe<Array<LineItemInput>>;
};

export type ImageItemInput = {
  key: Scalars['String'];
  name: Scalars['String'];
  objectId: Scalars['String'];
};

/** Combines all possible fields for LineItem. Ensure that fields match requirements from corresponding LineItem types. */
export type LineItemInput = {
  amount?: InputMaybe<Scalars['Float']>;
  /** required when type is 'Cost' */
  category?: InputMaybe<LineItemCostCategory>;
  key: Scalars['String'];
  name: Scalars['String'];
  productMeta?: InputMaybe<ProductMetaInput>;
  rebateItems?: InputMaybe<RebateItemsInput>;
  type: LineItemType;
  value?: InputMaybe<Scalars['String']>;
};

export enum LineItemCostCategory {
  Expenses = 'Expenses',
  Fees = 'Fees',
  Labor = 'Labor',
  Materials = 'Materials',
  Misc = 'Misc',
  Permits = 'Permits',
  Services = 'Services',
  Taxes = 'Taxes'
}

export type ProductMetaInput = {
  serialNumber?: InputMaybe<Scalars['String']>;
  vinNumber?: InputMaybe<Scalars['String']>;
};

export type RebateItemsInput = {
  evCharger?: InputMaybe<Scalars['Float']>;
  panelUpgrade?: InputMaybe<Scalars['Float']>;
};

export enum LineItemType {
  Cost = 'Cost',
  Detail = 'Detail',
  Product = 'Product',
  Program = 'Program',
  Rebate = 'Rebate'
}

export type SubmitJobDetailsOutput = {
  __typename?: 'SubmitJobDetailsOutput';
  jobDetails: JobDetails;
};

export type SubmitUnsuccessfulContactAttemptInput = {
  contactDate: Scalars['DateTime'];
  contactMethod: Array<ContactMethod>;
  requestNo: Scalars['String'];
};

export type SubmitUnsuccessfulContactAttemptOutput = {
  __typename?: 'SubmitUnsuccessfulContactAttemptOutput';
  request: Request;
};

export type BillingContactInfoInput = {
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type UpdateBillingContactInfoOutput = {
  __typename?: 'UpdateBillingContactInfoOutput';
  success: Scalars['Boolean'];
};

export type UpdateJobInput = {
  isOpened: Scalars['Boolean'];
  jobId: Scalars['ID'];
};

export type UpdateJobOutput = {
  __typename?: 'UpdateJobOutput';
  job: Job;
};

export type UpdateOperationsUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateOperationsUserOutput = {
  __typename?: 'UpdateOperationsUserOutput';
  operationsUser?: Maybe<OperationsUser>;
};

export type UpdateRequestInput = {
  isOpened?: InputMaybe<Scalars['Boolean']>;
  requestNo: Scalars['String'];
};

export type UpdateRequestOutput = {
  __typename?: 'UpdateRequestOutput';
  request: Request;
};

export type UpdateSectionErAccountInfoInput = {
  applicationId: Scalars['ID'];
  homeType?: InputMaybe<HomeType>;
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  preferredContact?: InputMaybe<ContactInfoInput>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
  useAccountContact?: InputMaybe<Scalars['Boolean']>;
};

export enum HomeType {
  Apartment = 'APARTMENT',
  Condo = 'CONDO',
  Detached = 'DETACHED',
  Duplex = 'DUPLEX',
  Townhouse = 'TOWNHOUSE',
  Triplex = 'TRIPLEX'
}

export type NavHistoryEntryInput = {
  path: Scalars['String'];
};

export type ContactInfoInput = {
  emailAddress?: InputMaybe<Scalars['EmailAddress']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
};

export type UpdateSectionErAccountInfoOutput = {
  __typename?: 'UpdateSectionERAccountInfoOutput';
  section: SectionErAccountInfo;
  validation?: Maybe<ValidationResult>;
};

export type SectionErAccountInfo = ApplicationSection & SectionEr & {
  __typename?: 'SectionERAccountInfo';
  data: SectionErAccountInfoData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationERSectionType;
  status: ApplicationSectionStatus;
};

export type SectionErAccountInfoData = {
  __typename?: 'SectionERAccountInfoData';
  accountContact: Contact;
  accountInfoInput?: Maybe<SectionErAccountInfoInput>;
  address?: Maybe<Address>;
};

export type SectionErAccountInfoInput = {
  __typename?: 'SectionERAccountInfoInput';
  homeType?: Maybe<HomeType>;
  preferredContact?: Maybe<PreferredContact>;
  useAccountContact?: Maybe<Scalars['Boolean']>;
};

/** shared types */
export type PreferredContact = {
  __typename?: 'PreferredContact';
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String'];
  city: Scalars['String'];
  postal: Scalars['String'];
  state: Scalars['String'];
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  path?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
};

export type UpdateSectionErRebateConfirmInput = {
  /** required for completion */
  agreeToTermsAndConditions?: InputMaybe<Scalars['Boolean']>;
  applicationId: Scalars['ID'];
  /** required if attempting a standard charger rebate */
  chargerInstallInfo?: InputMaybe<ErChargerInstallInfoInput>;
  /** required for completion */
  confirmChargerConnectedToResidentialMeter?: InputMaybe<Scalars['Boolean']>;
  /** required for completion */
  confirmDriveElectricVehicle?: InputMaybe<Scalars['Boolean']>;
  /** required when customer sees that they are eligible for some kind of standard rebate */
  confirmEligibility?: InputMaybe<Scalars['Boolean']>;
  /** required second time customer sees that they are eligible for some kind of standard or BYOC rebate  */
  confirmEligibility2?: InputMaybe<Scalars['Boolean']>;
  /** customer is only applying for evPulse rebate  */
  evPulseOnlyQualification?: InputMaybe<ErEvPulseOnlyQualificationInput>;
  id: Scalars['ID'];
  iqbdQualification?: InputMaybe<EvRebateIqbdInput>;
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  rebateQualification?: InputMaybe<ErRebateQualificationInput>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export type ErChargerInstallInfoInput = {
  /** required */
  chargerCost?: InputMaybe<Scalars['Float']>;
  /** required */
  installationCost?: InputMaybe<Scalars['Float']>;
  /** required, when the charger was installed */
  installationDate?: InputMaybe<Scalars['DateTime']>;
  /** @deprecated */
  itemizedCost?: InputMaybe<Array<LineItemCostInput>>;
  /** whether or not the panel was upgraded, required */
  panelUpgraded?: InputMaybe<Scalars['Boolean']>;
  /** required if after QPL date, c2m can only handle a max of 4 as of now */
  receiptImages?: InputMaybe<Array<StoreFileInput>>;
  /** required if after QPL date, @deprecated */
  totalInstalationCost?: InputMaybe<Scalars['Float']>;
};

export type LineItemCostInput = {
  amount: Scalars['Float'];
  category: LineItemCostCategory;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type StoreFileInput = {
  /** file upload service store key */
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
};

export type ErEvPulseOnlyQualificationInput = {
  /** required if connectedToApp is false. application rejected if false */
  confirmConnectedToApp?: InputMaybe<Scalars['Boolean']>;
  /** required */
  connectedToApp?: InputMaybe<Scalars['Boolean']>;
};

export type EvRebateIqbdInput = {
  /** required */
  checkEligibility?: InputMaybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  grossIncome?: InputMaybe<Scalars['Float']>;
  /** required if checkEligibility */
  grossIncomeConfirmed?: InputMaybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  houseHoldSize?: InputMaybe<Scalars['Int']>;
};

export type ErRebateQualificationInput = {
  /** when wifiAvailable === false */
  evPulse?: InputMaybe<ErEvPulseQualificationInput>;
  /** when wifiAvailable === true */
  smartCharging?: InputMaybe<ErSmartChargingQualificationInput>;
  /** required */
  wifiAvailable?: InputMaybe<Scalars['Boolean']>;
};

export type ErEvPulseQualificationInput = {
  /** required if connectedEvPulseApp === false, application rejected if false */
  chargerActivatedInAppConfirmed?: InputMaybe<Scalars['Boolean']>;
  /** required if willConnectEVPulseApp */
  connectedEvPulseApp?: InputMaybe<Scalars['Boolean']>;
  /** VIN number, required if connectEVPulseApp === true */
  vin?: InputMaybe<Scalars['String']>;
  /** image id, required if connectEVPulseApp === true */
  vinImage?: InputMaybe<StoreFileInput>;
  /**
   * the customer has a Tesla and is willing to connect PGE to evPulse app,
   * required if wifiAvailable === false.
   * application rejected if false
   */
  willConnectEVPulseApp?: InputMaybe<Scalars['Boolean']>;
};

export type ErSmartChargingQualificationInput = {
  /** required if wifiAvailable === false */
  chargerActivatedInApp?: InputMaybe<Scalars['Boolean']>;
  /** required if chargerActivatedInApp === false, application rejected if false */
  chargerActivatedInAppConfirmed?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSectionErRebateConfirmOutput = {
  __typename?: 'UpdateSectionERRebateConfirmOutput';
  section: SectionErRebateConfirm;
  validation?: Maybe<ValidationResult>;
};

export type SectionErRebateConfirm = ApplicationSection & SectionEr & {
  __typename?: 'SectionERRebateConfirm';
  data: SectionErRebateConfirmData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationERSectionType;
  status: ApplicationSectionStatus;
};

export type SectionErRebateConfirmData = {
  __typename?: 'SectionERRebateConfirmData';
  /** The applicable rebate with amounts adjusted for actual payout to the customer. */
  appliedRebate?: Maybe<EvRebate>;
  /** populated if smartCharging rebate (isQualifiedProduct) and updated on changes to iqbdQualification */
  preRebateEligibility1?: Maybe<EvRebateEligibility>;
  /** populated upon chargerInstallInfo update */
  preRebateEligibility2?: Maybe<EvRebateEligibility>;
  rebateConfirmInput: SectionErRebateConfirmInput;
  /** Final rebate eligibility. This represents the rebate that can be applied (max amount). */
  rebateEligibility?: Maybe<EvRebateEligibility>;
  /** The customer's available and existing EV rebate information. */
  rebateInfo: EvRebateInfo;
};

export type EvRebate = RebateByoc | RebateEvPulse | RebateSmartCharging;

export type RebateByoc = Rebate & {
  __typename?: 'RebateBYOC';
  amount: Scalars['Float'];
};

export type Rebate = {
  amount: Scalars['Float'];
};

export type RebateEvPulse = Rebate & {
  __typename?: 'RebateEVPulse';
  amount: Scalars['Float'];
};

export type RebateSmartCharging = Rebate & {
  __typename?: 'RebateSmartCharging';
  /** total possible rebate amount */
  amount: Scalars['Float'];
  charger: Scalars['Float'];
  /** whether or not the rebate has higher discount for IQBD. @deprecated use isHigherLevel instead */
  iqdb: Scalars['Boolean'];
  /** whether or not the rebate has higher discount, usually because of iqbd */
  isHigherDiscount: Scalars['Boolean'];
  panelUpgrade: Scalars['Float'];
};

export type EvRebateEligibility = {
  __typename?: 'EVRebateEligibility';
  eligible: Scalars['Boolean'];
  evRebate?: Maybe<EvRebate>;
  ineligibilityReason?: Maybe<RebateIneligibilityCode>;
  /** @deprecated use evRebate */
  rebate?: Maybe<Rebate>;
};

export enum RebateIneligibilityCode {
  /** Generic code as c2m doesn't provide any reasons whey the customer may not be eligibile. */
  C2MNotEligible = 'C2M_NOT_ELIGIBLE',
  /** Charger is for an existing vehicle already enrolled. */
  ChargerForExistingVehicle = 'CHARGER_FOR_EXISTING_VEHICLE',
  /** The customer already has the maximum number of rebates allowed. */
  MaxRebates = 'MAX_REBATES'
}

export type SectionErRebateConfirmInput = {
  __typename?: 'SectionERRebateConfirmInput';
  /** required for completion */
  agreeToTermsAndConditions?: Maybe<Scalars['Boolean']>;
  /** required if attempting a standard charger rebate */
  chargerInstallInfo?: Maybe<ErChargerInstallInfo>;
  /** required for completion */
  confirmChargerConnectedToResidentialMeter?: Maybe<Scalars['Boolean']>;
  /** required for completion */
  confirmDriveElectricVehicle?: Maybe<Scalars['Boolean']>;
  /** required when customer sees that they are eligible for some kind of standard rebate */
  confirmEligibility?: Maybe<Scalars['Boolean']>;
  /** required second time customer sees that they are eligible for some kind of standard or BYOC rebate  */
  confirmEligibility2?: Maybe<Scalars['Boolean']>;
  /** customer is only applying for evPulse rebate  */
  evPulseOnlyQualification?: Maybe<ErEvPulseOnlyQualification>;
  iqbdQualification?: Maybe<EvRebateIqbd>;
  rebateQualification?: Maybe<ErRebateQualification>;
};

export type ErChargerInstallInfo = {
  __typename?: 'ERChargerInstallInfo';
  /** required */
  chargerCost?: Maybe<Scalars['Float']>;
  /** required */
  installationCost?: Maybe<Scalars['Float']>;
  /** required, when the charger was installed */
  installationDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated use chargerCost and installationCost */
  itemizedCost?: Maybe<Array<LineItemCost>>;
  /** whether or not the panel was upgraded, required */
  panelUpgraded?: Maybe<Scalars['Boolean']>;
  /** required if after QPL date */
  receiptImages?: Maybe<Array<StoreFile>>;
  /** required if after QPL date, @deprecated */
  totalInstalationCost?: Maybe<Scalars['Float']>;
};

export type LineItemCost = LineItem & {
  __typename?: 'LineItemCost';
  amount: Scalars['Float'];
  category: LineItemCostCategory;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type StoreFile = {
  __typename?: 'StoreFile';
  /** file upload service store key */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ErEvPulseOnlyQualification = {
  __typename?: 'EREvPulseOnlyQualification';
  /** required if connectedToApp is false. application rejected if false */
  confirmConnectedToApp?: Maybe<Scalars['Boolean']>;
  /** required */
  connectedToApp?: Maybe<Scalars['Boolean']>;
};

export type EvRebateIqbd = {
  __typename?: 'EVRebateIQBD';
  /** required */
  checkEligibility?: Maybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  grossIncome?: Maybe<Scalars['Float']>;
  /** required if checkEligibility */
  grossIncomeConfirmed?: Maybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  houseHoldSize?: Maybe<Scalars['Int']>;
};

export type ErRebateQualification = {
  __typename?: 'ERRebateQualification';
  /** when wifiAvailable === false */
  evPulse?: Maybe<ErEvPulseQualification>;
  /** when wifiAvailable === true */
  smartCharging?: Maybe<ErSmartChargingQualification>;
  /** required */
  wifiAvailable?: Maybe<Scalars['Boolean']>;
};

export type ErEvPulseQualification = {
  __typename?: 'EREvPulseQualification';
  /** required if connectedEvPulseApp === false, application rejected if false */
  chargerActivatedInAppConfirmed?: Maybe<Scalars['Boolean']>;
  /** required if willConnectEVPulseApp */
  connectedEvPulseApp?: Maybe<Scalars['Boolean']>;
  /** VIN number, required if connectEVPulseApp === true */
  vin?: Maybe<Scalars['String']>;
  /** image id, required if connectEVPulseApp === true */
  vinImage?: Maybe<StoreFile>;
  /**
   * the customer has a Tesla and is willing to connect PGE to evPulse app,
   * required if wifiAvailable === false.
   * application rejected if false
   */
  willConnectEVPulseApp?: Maybe<Scalars['Boolean']>;
};

export type ErSmartChargingQualification = {
  __typename?: 'ERSmartChargingQualification';
  /** required if wifiAvailable === false */
  chargerActivatedInApp?: Maybe<Scalars['Boolean']>;
  /** required if chargerActivatedInApp === false, application rejected if false */
  chargerActivatedInAppConfirmed?: Maybe<Scalars['Boolean']>;
};

export type EvRebateInfo = {
  __typename?: 'EVRebateInfo';
  availability: EvRebateAvailability;
  existingEnrollments: EvRebateEnrollments;
};

export type EvRebateAvailability = {
  __typename?: 'EVRebateAvailability';
  /** whether or not the account can apply for IQBD. @deprecated use isAbleToRequestIQBD */
  iqdbEnroll: Scalars['Boolean'];
  /** true if the customer is not already enrolled in iqbd */
  isAbleToRequestIQBD: Scalars['Boolean'];
  /** whether or not the account can apply for the smart charging rebate program */
  smartChargingRebate: Scalars['Boolean'];
};

export type EvRebateEnrollments = {
  __typename?: 'EVRebateEnrollments';
  evPulse?: Maybe<Array<EvPulseEnrollment>>;
  /** whether nor not the account is already enrolled in IQDB */
  iqbd?: Maybe<Scalars['Boolean']>;
  /** whether nor not the account is already enrolled in IQDB. @deprecated use iqbd */
  iqdb?: Maybe<Scalars['Boolean']>;
  smartCharging?: Maybe<Array<SmartChargingEnrollment>>;
};

export type EvPulseEnrollment = {
  __typename?: 'EVPulseEnrollment';
  vin: Scalars['String'];
};

export type SmartChargingEnrollment = {
  __typename?: 'SmartChargingEnrollment';
  serialNo: Scalars['String'];
};

export type UpdateSectionErRebateInfoInput = {
  applicationId: Scalars['ID'];
  /** required true if mustVerifyResidentialMeter, application is rejected if false */
  chargerInstalledOnResidentialMeter?: InputMaybe<Scalars['Boolean']>;
  evPulse?: InputMaybe<ErEvPulseConfirmationInput>;
  /** required if customer has 1 existing evPulse rebate */
  evPulseEnrollmentConfirmation?: InputMaybe<ErEvPulseEnrollmentConfirmationInput>;
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  /** required if chargerValidation.isQualifiedProduct */
  qualifiedProductInfo?: InputMaybe<ErQualifiedProductInfoInput>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  smartCharging?: InputMaybe<ErSmartChargingConfirmationInput>;
  /** required if customer has 1 existing SmartCharging rebate */
  smartChargingEnrollmentConfirmation?: InputMaybe<ErSmartChargingEnrollmentConfirmationInput>;
  status: ApplicationSectionStatus;
};

export type ErEvPulseConfirmationInput = {
  /** customer confirms they drive a Tesla vehicle, rejects application if false */
  driveTesla?: InputMaybe<Scalars['Boolean']>;
  /** required if driveTesla, rejects if false */
  hasL2Charger?: InputMaybe<Scalars['Boolean']>;
  /** required if hasL2Charger, rejects if not unique VIN */
  vin?: InputMaybe<Scalars['String']>;
  /** upload store ID of image, required if hasL2Charger */
  vinImage?: InputMaybe<StoreFileInput>;
};

export type ErEvPulseEnrollmentConfirmationInput = {
  willEnrollNewSmartChargingDevice?: InputMaybe<Scalars['Boolean']>;
  /** required if willEnrollNewSmartChargingDevice === false, application rejected if false */
  willEnrollNewVehicle?: InputMaybe<Scalars['Boolean']>;
};

export type ErQualifiedProductInfoInput = {
  /** for juicebox id or serialNo. application rejected if already enrolled */
  serialNumber?: InputMaybe<Scalars['String']>;
  /** upload store ID of image */
  serialNumberImage?: InputMaybe<StoreFileInput>;
};

export type ErSmartChargingConfirmationInput = {
  /** required */
  modelNumber?: InputMaybe<Scalars['String']>;
  /** required, upload store ID of image */
  modelNumberImage?: InputMaybe<StoreFileInput>;
};

/** when the customer has an existing EV rebate enrollment for a smart charging device */
export type ErSmartChargingEnrollmentConfirmationInput = {
  willEnrollNewSmartChargingDevice?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSectionErRebateInfoOutput = {
  __typename?: 'UpdateSectionERRebateInfoOutput';
  section: SectionErRebateInfo;
  validation?: Maybe<ValidationResult>;
};

/** Maintains and validates data for the "Confirm your charger" section of the UI. */
export type SectionErRebateInfo = ApplicationSection & SectionEr & {
  __typename?: 'SectionERRebateInfo';
  data: SectionErRebateInfoData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationERSectionType;
  status: ApplicationSectionStatus;
};

export type SectionErRebateInfoData = {
  __typename?: 'SectionERRebateInfoData';
  /** Charger validation, populates once model number is provided */
  chargerValidation?: Maybe<ErChargerValidation>;
  /** whether or not the customer must verify they are installing to a residential meter */
  mustVerifyResidentialMeter?: Maybe<Scalars['Boolean']>;
  /**
   * The customer's available and existing EV rebate information.
   * TOOD: this should be required!
   */
  rebateInfo: EvRebateInfo;
  rebateInfoInput?: Maybe<SectionErRebateInfoInput>;
};

export type ErChargerValidation = {
  __typename?: 'ERChargerValidation';
  isQualifiedProduct: Scalars['Boolean'];
  product?: Maybe<EvProduct>;
};

export type EvProduct = {
  __typename?: 'EVProduct';
  brand?: Maybe<Scalars['String']>;
  /** not populated yet */
  dateAdded?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  modelNo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type SectionErRebateInfoInput = {
  __typename?: 'SectionERRebateInfoInput';
  /** required true if mustVerifyResidentialMeter, application is rejected if false */
  chargerInstalledOnResidentialMeter?: Maybe<Scalars['Boolean']>;
  evPulse?: Maybe<ErEvPulseConfirmation>;
  /** required if customer has 1 existing evPulse rebate */
  evPulseEnrollmentConfirmation?: Maybe<ErEvPulseEnrollmentConfirmation>;
  /** required if chargerValidation.isQualifiedProduct */
  qualifiedProductInfo?: Maybe<ErQualifiedProductInfo>;
  smartCharging?: Maybe<ErSmartChargingConfirmation>;
  /** required if customer has 1 existing SmartCharging rebate */
  smartChargingEnrollmentConfirmation?: Maybe<ErSmartChargingEnrollmentConfirmation>;
};

export type ErEvPulseConfirmation = {
  __typename?: 'EREvPulseConfirmation';
  /** required, customer confirms they drive a Tesla vehicle, rejects application if false */
  driveTesla?: Maybe<Scalars['Boolean']>;
  /** required if driveTesla, rejects if false */
  hasL2Charger?: Maybe<Scalars['Boolean']>;
  /** required if hasL2Charger, rejects if not unique VIN */
  vin?: Maybe<Scalars['String']>;
  /** upload store ID of image, required if hasL2Charger */
  vinImage?: Maybe<StoreFile>;
};

/** when the customer has an existing EV rebate enrollment for an evPulse device */
export type ErEvPulseEnrollmentConfirmation = {
  __typename?: 'EREvPulseEnrollmentConfirmation';
  willEnrollNewSmartChargingDevice?: Maybe<Scalars['Boolean']>;
  /** required if willEnrollNewSmartChargingDevice === false, application rejected if false */
  willEnrollNewVehicle?: Maybe<Scalars['Boolean']>;
};

export type ErQualifiedProductInfo = {
  __typename?: 'ERQualifiedProductInfo';
  /** for juicebox id or serialNo. application rejected if already enrolled */
  serialNumber?: Maybe<Scalars['String']>;
  /** upload store ID of image */
  serialNumberImage?: Maybe<StoreFile>;
};

export type ErSmartChargingConfirmation = {
  __typename?: 'ERSmartChargingConfirmation';
  /** required */
  modelNumber?: Maybe<Scalars['String']>;
  /** required, upload store ID of image */
  modelNumberImage?: Maybe<StoreFile>;
};

/** when the customer has an existing EV rebate enrollment for a smart charging device */
export type ErSmartChargingEnrollmentConfirmation = {
  __typename?: 'ERSmartChargingEnrollmentConfirmation';
  willEnrollNewSmartChargingDevice?: Maybe<Scalars['Boolean']>;
};

export type UpdateSectionEvAccountInfoInput = {
  applicationId: Scalars['ID'];
  homeType?: InputMaybe<HomeType>;
  id: Scalars['ID'];
  isOwner?: InputMaybe<Scalars['Boolean']>;
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  preferredContact?: InputMaybe<ContactInfoInput>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
  useAccountContact?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSectionEvAccountInfoOutput = {
  __typename?: 'UpdateSectionEVAccountInfoOutput';
  section: SectionEvAccountInfo;
  validation?: Maybe<ValidationResult>;
};

export type SectionEvAccountInfo = ApplicationSection & SectionEv & {
  __typename?: 'SectionEVAccountInfo';
  data: SectionEvAccountInfoData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationEVSectionType;
  status: ApplicationSectionStatus;
};

export type SectionEvAccountInfoData = {
  __typename?: 'SectionEVAccountInfoData';
  accountContact: Contact;
  address?: Maybe<Address>;
  homeType?: Maybe<HomeType>;
  isOwner?: Maybe<Scalars['Boolean']>;
  /** Preferred contact information that is different from account contact. */
  preferredContact?: Maybe<PreferredContact>;
  useAccountContact?: Maybe<Scalars['Boolean']>;
};

export type UpdateSectionEvChargerSelectionInput = {
  applicationId: Scalars['ID'];
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  /** the selected product id or 'NONE' */
  productId?: InputMaybe<Scalars['String']>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export type UpdateSectionEvChargerSelectionOutput = {
  __typename?: 'UpdateSectionEVChargerSelectionOutput';
  section: SectionEvChargerSelection;
  validation?: Maybe<ValidationResult>;
};

export type SectionEvChargerSelection = ApplicationSection & SectionEv & {
  __typename?: 'SectionEVChargerSelection';
  data: SectionEvChargerSelectionData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationEVSectionType;
  status: ApplicationSectionStatus;
};

export type SectionEvChargerSelectionData = {
  __typename?: 'SectionEVChargerSelectionData';
  /** the selected product id or 'NONE' */
  productId?: Maybe<Scalars['String']>;
  /** this should be assigned at time of application creation */
  suppliedByCustomer: Scalars['Boolean'];
};

export type UpdateSectionEvEstimateInput = {
  acknowledgedEstimate?: InputMaybe<Scalars['Boolean']>;
  acknowledgedInstallerInfo?: InputMaybe<Scalars['Boolean']>;
  applicationId: Scalars['ID'];
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export type UpdateSectionEvEstimateOutput = {
  __typename?: 'UpdateSectionEVEstimateOutput';
  section: SectionEvEstimate;
  validation?: Maybe<ValidationResult>;
};

export type SectionEvEstimate = ApplicationSection & SectionEv & {
  __typename?: 'SectionEVEstimate';
  data: SectionEvEstimateData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationEVSectionType;
  status: ApplicationSectionStatus;
};

export type SectionEvEstimateData = {
  __typename?: 'SectionEVEstimateData';
  /** required if ineligibleEstimateReason is empty */
  acknowledgedEstimate?: Maybe<Scalars['Boolean']>;
  acknowledgedInstallerInfo?: Maybe<Scalars['Boolean']>;
  /** Only available if ineligibleEstimateReason is empty. */
  estimateType?: Maybe<EstimateTypeEV>;
  /** The reason an estimate is not availble. */
  ineligibleEstimateReason?: Maybe<IneligibleEVEstimateReason>;
  lineItems?: Maybe<Array<LineItem>>;
  subtotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export enum EstimateTypeEv {
  StandardGarage = 'STANDARD_GARAGE',
  StandardOutdoor = 'STANDARD_OUTDOOR'
}

export enum IneligibleEvEstimateReason {
  BreakerDistanceTooFar = 'BREAKER_DISTANCE_TOO_FAR',
  DoubleBreakerSpaceUnavailableOrUnknown = 'DOUBLE_BREAKER_SPACE_UNAVAILABLE_OR_UNKNOWN',
  InstallerToRecommend = 'INSTALLER_TO_RECOMMEND',
  UnknownBreakerAmperage = 'UNKNOWN_BREAKER_AMPERAGE',
  UnknownInstallLocation = 'UNKNOWN_INSTALL_LOCATION'
}

export type UpdateSectionEvInstallInput = {
  applicationId: Scalars['ID'];
  chargerInstallPreference?: InputMaybe<ChargerInstallInput>;
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  panelLocation?: InputMaybe<PanelLocationInput>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  schedulePreference?: InputMaybe<InstallScheduleInput>;
  status: ApplicationSectionStatus;
};

export type ChargerInstallInput = {
  /** Not required if "installerRecommended" */
  distanceToPanel?: InputMaybe<PanelDistance>;
  installerRecommendPanelDistance?: InputMaybe<Scalars['Boolean']>;
  /** Only required if panel location is *not* GARAGE_ATTACHED or OUTSIDE */
  installLocation?: InputMaybe<ChargerInstallLocation>;
  panelSideLocation?: InputMaybe<PanelSideLocation>;
};

export enum PanelDistance {
  Btw_6_30 = 'BTW_6_30',
  Gt_30 = 'GT_30',
  Lt_5 = 'LT_5'
}

export enum ChargerInstallLocation {
  Garage = 'GARAGE',
  Outside = 'OUTSIDE'
}

export enum PanelSideLocation {
  BackWall = 'BACK_WALL',
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type PanelLocationInput = {
  /** Optional field to use when location is OTHER */
  comment?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<PanelLocation>;
};

export enum PanelLocation {
  Basement = 'BASEMENT',
  GarageAttached = 'GARAGE_ATTACHED',
  Inside = 'INSIDE',
  NotSure = 'NOT_SURE',
  Other = 'OTHER',
  Outside = 'OUTSIDE'
}

/** shared inputs */
export type InstallScheduleInput = {
  dayOfWeek?: InputMaybe<Array<DayOfWeek>>;
  preferredServiceTime?: InputMaybe<Array<Scalars['String']>>;
  /** deprecated */
  timeFrame?: InputMaybe<Array<TimeFrame>>;
  urgency?: InputMaybe<Scalars['String']>;
};

export enum DayOfWeek {
  Any = 'ANY',
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

/** @deprecated */
export enum TimeFrame {
  Afternoon = 'AFTERNOON',
  Any = 'ANY',
  Morning = 'MORNING'
}

export type UpdateSectionEvInstallOutput = {
  __typename?: 'UpdateSectionEVInstallOutput';
  section: SectionEvInstall;
  validation?: Maybe<ValidationResult>;
};

export type SectionEvInstall = ApplicationSection & SectionEv & {
  __typename?: 'SectionEVInstall';
  data: SectionEvInstallData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationEVSectionType;
  status: ApplicationSectionStatus;
};

export type SectionEvInstallData = {
  __typename?: 'SectionEVInstallData';
  chargerInstallPreference?: Maybe<ChargerInstallPreference>;
  panelLocation?: Maybe<PanelLocationInfo>;
  schedulePreference?: Maybe<InstallSchedulePreference>;
};

export type ChargerInstallPreference = {
  __typename?: 'ChargerInstallPreference';
  /** Not required if "installerRecommended" */
  distanceToPanel?: Maybe<PanelDistance>;
  /** Customer would like the installer to recommend the installation distance to the panel. */
  installerRecommendPanelDistance?: Maybe<Scalars['Boolean']>;
  /** Only required if panel location is *not* GARAGE_ATTACHED or OUTSIDE */
  installLocation?: Maybe<ChargerInstallLocation>;
  panelSideLocation?: Maybe<PanelSideLocation>;
};

export type PanelLocationInfo = {
  __typename?: 'PanelLocationInfo';
  /** Optional field to use when location is OTHER */
  comment?: Maybe<Scalars['String']>;
  location?: Maybe<PanelLocation>;
};

export type InstallSchedulePreference = {
  __typename?: 'InstallSchedulePreference';
  dayOfWeek?: Maybe<Array<DayOfWeek>>;
  preferredServiceTime?: Maybe<Array<Scalars['String']>>;
  /** @deprecated */
  timeFrame?: Maybe<Array<TimeFrame>>;
  urgency?: Maybe<Scalars['String']>;
};

export type UpdateSectionEvPanelInfoInput = {
  amperageImage?: InputMaybe<Scalars['String']>;
  applicationId: Scalars['ID'];
  breakerInfo?: InputMaybe<BreakerInfoInput>;
  /** Only required if charger is supplied by customer. */
  chargerCost?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Only required if panel distance is >= 6 ft */
  installDistance?: InputMaybe<ChargerInstallDistanceInput>;
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  panelImage?: InputMaybe<Scalars['String']>;
  panelLocationImage?: InputMaybe<Scalars['String']>;
  /** Only required if charger is supplied by customer. */
  receiptImage?: InputMaybe<Scalars['String']>;
  receiptImageUrl?: InputMaybe<Scalars['URL']>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export type BreakerInfoInput = {
  doubleBreakerAvailable?: InputMaybe<DoubleBreakerAvailable>;
  mainBreakerAmperage?: InputMaybe<MainBreakerAmperage>;
};

export enum DoubleBreakerAvailable {
  No = 'NO',
  Unknown = 'UNKNOWN',
  Yes = 'YES'
}

export enum MainBreakerAmperage {
  Amps_100 = 'AMPS_100',
  Amps_125 = 'AMPS_125',
  Amps_150 = 'AMPS_150',
  Amps_200 = 'AMPS_200',
  NoMainBreaker = 'NO_MAIN_BREAKER',
  Unknown = 'UNKNOWN'
}

export type ChargerInstallDistanceInput = {
  /**
   * Whether or not the customer wants the charger installed close (5-8ft) to their panel.
   * Required.
   */
  extendedInstallDistance?: InputMaybe<Scalars['Boolean']>;
  /** Required if extendedInstallDistance is true. */
  extendedInstallDistanceImage?: InputMaybe<Scalars['String']>;
};

export type UpdateSectionEvPanelInfoOutput = {
  __typename?: 'UpdateSectionEVPanelInfoOutput';
  section: SectionEvPanelInfo;
  validation?: Maybe<ValidationResult>;
};

export type SectionEvPanelInfo = ApplicationSection & SectionEv & {
  __typename?: 'SectionEVPanelInfo';
  data: SectionEvPanelInfoData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationEVSectionType;
  status: ApplicationSectionStatus;
};

export type SectionEvPanelInfoData = {
  __typename?: 'SectionEVPanelInfoData';
  /** The PGE upload storage key for the file. Required. */
  amperageImage?: Maybe<Scalars['String']>;
  amperageImageUrl?: Maybe<Scalars['URL']>;
  breakerInfo?: Maybe<BreakerInfo>;
  /** Only required if charger is supplied by customer. */
  chargerCost?: Maybe<Scalars['Float']>;
  /** Only required if panel distance is >= 6 ft */
  installDistance?: Maybe<ChargerInstallDistance>;
  /** The PGE upload storage key for the file. Required. */
  panelImage?: Maybe<Scalars['String']>;
  panelImageUrl?: Maybe<Scalars['URL']>;
  /** The PGE upload storage key for the file. Required. */
  panelLocationImage?: Maybe<Scalars['String']>;
  panelLocationImageUrl?: Maybe<Scalars['URL']>;
  /** Only required if charger is supplied by customer. */
  receiptImage?: Maybe<Scalars['String']>;
  receiptImageUrl?: Maybe<Scalars['URL']>;
};

export type BreakerInfo = {
  __typename?: 'BreakerInfo';
  doubleBreakerAvailable?: Maybe<DoubleBreakerAvailable>;
  mainBreakerAmperage?: Maybe<MainBreakerAmperage>;
};

export type ChargerInstallDistance = {
  __typename?: 'ChargerInstallDistance';
  /**
   * Whether or not the customer wants the charger installed close (5-8ft) to their panel.
   * Required.
   */
  extendedInstallDistance?: Maybe<Scalars['Boolean']>;
  /** The PGE upload storage key for the file. Required if extendedInstallDistance is true. */
  extendedInstallDistanceImage?: Maybe<Scalars['String']>;
  extendedInstallDistanceImageUrl?: Maybe<Scalars['URL']>;
};

export type UpdateSectionEvRebatesInput = {
  applicationId: Scalars['ID'];
  /** required to be true when completing the section */
  confirmed?: InputMaybe<Scalars['Boolean']>;
  /**
   * required if availability.smartCharging and (
   *    smartCharging.activateChargerInApp === false or smartCharging.wifiAvailable === false
   * )
   */
  evPulse?: InputMaybe<EvRebateEvPulseInput>;
  id: Scalars['ID'];
  /** required if availability.iqdb */
  iqdb?: InputMaybe<EvRebateIqdbInput>;
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  /** required if availability.smartCharging */
  smartCharging?: InputMaybe<EvRebateSmartChargingInput>;
  status: ApplicationSectionStatus;
};

export type EvRebateEvPulseInput = {
  /**  required value if existingEnrollments.evPulse.length  */
  chargerForExistingVehicle?: InputMaybe<Scalars['Boolean']>;
  /** the customer has a Tesla and is willing to connect PGE to evPulse app */
  connectEVPulseApp?: InputMaybe<Scalars['Boolean']>;
  /**
   * required value if chargerForExistingVehicle. If set to false,
   * the application transitions to REJECTED.
   */
  continueWithoutRebate?: InputMaybe<Scalars['Boolean']>;
  /** VIN number of tesla charger, required if connectEVPulseApp */
  vin?: InputMaybe<Scalars['String']>;
  /** file upload service store key, required if connectEVPulseApp */
  vinImage?: InputMaybe<Scalars['String']>;
};

/** @deprecated use EVRebateIQBDInput instead */
export type EvRebateIqdbInput = {
  /** required */
  checkEligibility?: InputMaybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  grossIncome?: InputMaybe<Scalars['Float']>;
  /** required if checkEligibility */
  grossIncomeConfirmed?: InputMaybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  houseHoldSize?: InputMaybe<Scalars['Int']>;
};

export type EvRebateSmartChargingInput = {
  /** required if wifiAvailable */
  activateChargerInApp?: InputMaybe<Scalars['Boolean']>;
  /**  required value if existingEnrollments.smartCharging.length  */
  chargerForExistingVehicle?: InputMaybe<Scalars['Boolean']>;
  /**
   * required value if chargerForExistingVehicle. If set to false,
   * the application transitions to REJECTED.
   */
  continueWithoutRebate?: InputMaybe<Scalars['Boolean']>;
  /** required */
  wifiAvailable?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSectionEvRebatesOutput = {
  __typename?: 'UpdateSectionEVRebatesOutput';
  section: SectionEvRebates;
  validation?: Maybe<ValidationResult>;
};

export type SectionEvRebates = ApplicationSection & SectionEv & {
  __typename?: 'SectionEVRebates';
  data: SectionEvRebatesData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationEVSectionType;
  status: ApplicationSectionStatus;
};

export type SectionEvRebatesData = {
  __typename?: 'SectionEVRebatesData';
  /** what rebates or programs are available for the customer to apply for */
  availability: EvRebateAvailability;
  /**
   * The customer must view the final outcome of rebate eligibility before completing the section
   * unless they've chosen to abort
   */
  confirmed?: Maybe<Scalars['Boolean']>;
  /**
   * customer form input, required if
   * availability.smartChargingRebate and (
   *    smartCharging.activateChargerInApp === false or smartCharging.wifiAvailable === false
   * )
   */
  evPulse?: Maybe<EvRebateEvPulse>;
  /** existing rebate program enrollments associated with the account */
  existingEnrollments?: Maybe<EvRebateEnrollments>;
  /** customer form input, required if availability.iqdbEnroll */
  iqdb?: Maybe<EvRebateIqdb>;
  /** What rebates the customer has applied to the current application. */
  rebateEligibility?: Maybe<EvRebateEligibility>;
  /** customer form input, required if availability.smartChargingRebate */
  smartCharging?: Maybe<EvRebateSmartCharging>;
};

/** from form input */
export type EvRebateEvPulse = {
  __typename?: 'EVRebateEVPulse';
  /**  required value if existingEnrollments.evPulse.length  */
  chargerForExistingVehicle?: Maybe<Scalars['Boolean']>;
  /** the customer has a Tesla and is willing to connect PGE to evPulse app */
  connectEVPulseApp?: Maybe<Scalars['Boolean']>;
  /**
   * required value if chargerForExistingVehicle. If set to false,
   * the application transitions to REJECTED.
   */
  continueWithoutRebate?: Maybe<Scalars['Boolean']>;
  /** VIN number of tesla charger, required if connectEVPulseApp */
  vin?: Maybe<Scalars['String']>;
  /** file upload service store key, required if connectEVPulseApp */
  vinImage?: Maybe<Scalars['String']>;
};

/** from form input, @deprecated use EVRebateIQBD */
export type EvRebateIqdb = {
  __typename?: 'EVRebateIQDB';
  /** required */
  checkEligibility?: Maybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  grossIncome?: Maybe<Scalars['Float']>;
  /** required if checkEligibility */
  grossIncomeConfirmed?: Maybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  houseHoldSize?: Maybe<Scalars['Int']>;
};

/** from form input */
export type EvRebateSmartCharging = {
  __typename?: 'EVRebateSmartCharging';
  /** required if wifiAvailable */
  activateChargerInApp?: Maybe<Scalars['Boolean']>;
  /**  required value if existingEnrollments.smartCharging.length  */
  chargerForExistingVehicle?: Maybe<Scalars['Boolean']>;
  /**
   * required value if chargerForExistingVehicle. If set to false,
   * the application transitions to REJECTED. This indicates that the customer
   * does not wish to continue with the application process.
   */
  continueWithoutRebate?: Maybe<Scalars['Boolean']>;
  /** required if availability.smartCharging */
  wifiAvailable?: Maybe<Scalars['Boolean']>;
};

export type UpdateSectionEvSummaryInput = {
  applicationId: Scalars['ID'];
  confirmed?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export type UpdateSectionEvSummaryOutput = {
  __typename?: 'UpdateSectionEVSummaryOutput';
  section: SectionEvSummary;
  validation?: Maybe<ValidationResult>;
};

export type SectionEvSummary = ApplicationSection & SectionEv & {
  __typename?: 'SectionEVSummary';
  data: SectionEvSummaryData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationEVSectionType;
  status: ApplicationSectionStatus;
};

export type SectionEvSummaryData = {
  __typename?: 'SectionEVSummaryData';
  confirmed?: Maybe<Scalars['Boolean']>;
};

/** Customer Account Information */
export type UpdateSectionHvAccountInfoInput = {
  applicationId: Scalars['ID'];
  homeSqFtRange?: InputMaybe<HomeSquareFeetRange>;
  homeType?: InputMaybe<HomeType>;
  homeYearBuiltRange?: InputMaybe<HomeYearBuiltRange>;
  id: Scalars['ID'];
  isOwner?: InputMaybe<Scalars['Boolean']>;
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  preferredContact?: InputMaybe<ContactInfoInput>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
  useAccountContact?: InputMaybe<Scalars['Boolean']>;
};

export enum HomeSquareFeetRange {
  Above_3500 = 'ABOVE_3500',
  From_1000To_1499 = 'FROM_1000_TO_1499',
  From_1500To_1999 = 'FROM_1500_TO_1999',
  From_2000To_2499 = 'FROM_2000_TO_2499',
  From_2500To_2999 = 'FROM_2500_TO_2999',
  From_3000To_3499 = 'FROM_3000_TO_3499',
  Under_1000 = 'UNDER_1000'
}

export enum HomeYearBuiltRange {
  Before_1899 = 'BEFORE_1899',
  Between_1900To_1909 = 'BETWEEN_1900_TO_1909',
  Between_1910To_1919 = 'BETWEEN_1910_TO_1919',
  Between_1920To_1929 = 'BETWEEN_1920_TO_1929',
  Between_1930To_1939 = 'BETWEEN_1930_TO_1939',
  Between_1940To_1949 = 'BETWEEN_1940_TO_1949',
  Between_1950To_1959 = 'BETWEEN_1950_TO_1959',
  Between_1960To_1969 = 'BETWEEN_1960_TO_1969',
  Between_1970To_1979 = 'BETWEEN_1970_TO_1979',
  Between_1980To_1989 = 'BETWEEN_1980_TO_1989',
  Between_1990To_1999 = 'BETWEEN_1990_TO_1999',
  Between_2000To_2009 = 'BETWEEN_2000_TO_2009'
}

export type UpdateSectionHvAccountInfoOutput = {
  __typename?: 'UpdateSectionHVAccountInfoOutput';
  section: SectionHvAccountInfo;
  validation?: Maybe<ValidationResult>;
};

export type SectionHvAccountInfo = ApplicationSection & SectionHv & {
  __typename?: 'SectionHVAccountInfo';
  data: SectionHvAccountInfoData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationHVSectionType;
  status: ApplicationSectionStatus;
};

export type SectionHvAccountInfoData = {
  __typename?: 'SectionHVAccountInfoData';
  accountContact: Contact;
  address?: Maybe<Address>;
  homeSqFtRange?: Maybe<HomeSquareFeetRange>;
  homeType?: Maybe<HomeType>;
  homeYearBuiltRange?: Maybe<HomeYearBuiltRange>;
  isOwner?: Maybe<Scalars['Boolean']>;
  preferredContact?: Maybe<PreferredContact>;
  useAccountContact?: Maybe<Scalars['Boolean']>;
};

/** HVAC Estimate */
export type UpdateSectionHvEstimateInput = {
  acknowledgedEstimate: Scalars['Boolean'];
  acknowledgedETO: Scalars['Boolean'];
  acknowledgedInfoPassedToInstaller: Scalars['Boolean'];
  acknowledgedPTROrST: Scalars['Boolean'];
  applicationId: Scalars['ID'];
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export type UpdateSectionHvEstimateOutput = {
  __typename?: 'UpdateSectionHVEstimateOutput';
  section: SectionHvEstimate;
  validation?: Maybe<ValidationResult>;
};

export type SectionHvEstimate = ApplicationSection & SectionHv & {
  __typename?: 'SectionHVEstimate';
  data: SectionHvEstimateData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationHVSectionType;
  status: ApplicationSectionStatus;
};

/** HVAC Estimate */
export type SectionHvEstimateData = {
  __typename?: 'SectionHVEstimateData';
  acknowledgedEstimate: Scalars['Boolean'];
  acknowledgedETO: Scalars['Boolean'];
  acknowledgedInfoPassedToInstaller: Scalars['Boolean'];
  acknowledgedPTROrST: Scalars['Boolean'];
};

/** HVAC Incentives */
export type UpdateSectionHvIncentivesInput = {
  applicationId: Scalars['ID'];
  id: Scalars['ID'];
  incentivesForPTR?: InputMaybe<IncentivesForPtrInput>;
  incentivesSmartForThermostat?: InputMaybe<IncentivesForSmartThermostatInput>;
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export type IncentivesForPtrInput = {
  contactInfoPTR?: InputMaybe<ProgramContactInfoInput>;
  isEnrollPTR: Scalars['Boolean'];
};

export type ProgramContactInfoInput = {
  email: Scalars['EmailAddress'];
  phoneNumber: Scalars['String'];
};

export type IncentivesForSmartThermostatInput = {
  contactInfoSmartThermostat?: InputMaybe<ProgramContactInfoInput>;
  existingThermostat: ThermostatModel;
  isEnrollSmartThermostat: Scalars['Boolean'];
};

export enum ThermostatModel {
  Ecobee = 'ECOBEE',
  GoogleNest = 'GOOGLE_NEST',
  HoneywellResideo = 'HONEYWELL_RESIDEO',
  HoneywellTotalConnectComfort = 'HONEYWELL_TOTAL_CONNECT_COMFORT',
  None = 'NONE',
  Sensi = 'SENSI'
}

export type UpdateSectionHvIncentivesOutput = {
  __typename?: 'UpdateSectionHVIncentivesOutput';
  section: SectionHvIncentivesInfo;
  validation?: Maybe<ValidationResult>;
};

export type SectionHvIncentivesInfo = ApplicationSection & SectionHv & {
  __typename?: 'SectionHVIncentivesInfo';
  data: SectionHvIncentivesInfoData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationHVSectionType;
  status: ApplicationSectionStatus;
};

export type SectionHvIncentivesInfoData = {
  __typename?: 'SectionHVIncentivesInfoData';
  incentivesForPTR?: Maybe<IncentivesForPtr>;
  incentivesForSmartThermostat?: Maybe<IncentivesForSmartThermostat>;
};

/** HVAC Incentives */
export type IncentivesForPtr = {
  __typename?: 'IncentivesForPTR';
  contactInfoPTR?: Maybe<ProgramContactInfo>;
  isEnrollPTR: Scalars['Boolean'];
};

export type ProgramContactInfo = {
  __typename?: 'ProgramContactInfo';
  email: Scalars['EmailAddress'];
  phoneNumber: Scalars['String'];
};

export type IncentivesForSmartThermostat = {
  __typename?: 'IncentivesForSmartThermostat';
  contactInfoSmartThermostat?: Maybe<ProgramContactInfo>;
  existingThermostat: ThermostatModel;
  isEnrollSmartThermostat: Scalars['Boolean'];
};

/** HVAC Installation */
export type UpdateSectionHvInstallInput = {
  applicationId: Scalars['ID'];
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  schedulePreference?: InputMaybe<InstallScheduleInput>;
  status: ApplicationSectionStatus;
};

export type UpdateSectionHvInstallOutput = {
  __typename?: 'UpdateSectionHVInstallOutput';
  section: SectionHvInstall;
  validation?: Maybe<ValidationResult>;
};

export type SectionHvInstall = ApplicationSection & SectionHv & {
  __typename?: 'SectionHVInstall';
  data: SectionHvInstallData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationHVSectionType;
  status: ApplicationSectionStatus;
};

/** HVAC Install */
export type SectionHvInstallData = {
  __typename?: 'SectionHVInstallData';
  schedulePreference?: Maybe<InstallSchedulePreference>;
};

/** HVAC Summary */
export type UpdateSectionHvSummaryInput = {
  applicationId: Scalars['ID'];
  confirmed?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export type UpdateSectionHvSummaryOutput = {
  __typename?: 'UpdateSectionHVSummaryOutput';
  section: SectionHvSummary;
  validation?: Maybe<ValidationResult>;
};

export type SectionHvSummary = ApplicationSection & SectionHv & {
  __typename?: 'SectionHVSummary';
  data: SectionHvSummaryData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationHVSectionType;
  status: ApplicationSectionStatus;
};

/** HVAC Summary */
export type SectionHvSummaryData = {
  __typename?: 'SectionHVSummaryData';
  confirmed: Scalars['Boolean'];
};

/** HVAC System Information */
export type UpdateSectionHvSystemInfoInput = {
  applicationId: Scalars['ID'];
  currentCoolingSystemDesc?: InputMaybe<Scalars['String']>;
  currentCoolingSystems: Array<CoolingSystem>;
  currentHeatingSystemDesc?: InputMaybe<Scalars['String']>;
  currentHeatingSystems: Array<HeatingSystem>;
  id: Scalars['ID'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  preferredSystem: HVPreference;
  preferredSystemType: HVSystemType;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export enum CoolingSystem {
  CeilingOrPortableFan = 'CEILING_OR_PORTABLE_FAN',
  CentralAc = 'CENTRAL_AC',
  DuctedHeatPump = 'DUCTED_HEAT_PUMP',
  DuctlessHeatPump = 'DUCTLESS_HEAT_PUMP',
  None = 'NONE',
  PortableWindowAc = 'PORTABLE_WINDOW_AC'
}

export enum HeatingSystem {
  DuctedHeatPump = 'DUCTED_HEAT_PUMP',
  DuctlessHeatPump = 'DUCTLESS_HEAT_PUMP',
  Furnace = 'FURNACE',
  None = 'NONE',
  SpaceHeater = 'SPACE_HEATER',
  WoodOrPelletStove = 'WOOD_OR_PELLET_STOVE',
  ZonalBaseboard = 'ZONAL_BASEBOARD'
}

export enum HvPreference {
  Both = 'BOTH',
  Cooling = 'COOLING',
  Heating = 'HEATING'
}

export enum HvSystemType {
  CentralAc = 'CENTRAL_AC',
  DuctedHeatPump = 'DUCTED_HEAT_PUMP',
  DuctlessHeatPump = 'DUCTLESS_HEAT_PUMP'
}

export type UpdateSectionHvSystemInfoOutput = {
  __typename?: 'UpdateSectionHVSystemInfoOutput';
  section: SectionHvSystemInfo;
  validation?: Maybe<ValidationResult>;
};

export type SectionHvSystemInfo = ApplicationSection & SectionHv & {
  __typename?: 'SectionHVSystemInfo';
  data: SectionHvSystemInfoData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationHVSectionType;
  status: ApplicationSectionStatus;
};

/** HAVC System Info */
export type SectionHvSystemInfoData = {
  __typename?: 'SectionHVSystemInfoData';
  currentCoolingSystemDesc?: Maybe<Scalars['String']>;
  currentCoolingSystems?: Maybe<Array<CoolingSystem>>;
  currentHeatingSystemDesc?: Maybe<Scalars['String']>;
  currentHeatingSystems?: Maybe<Array<HeatingSystem>>;
  preferredSystem?: Maybe<HVPreference>;
  preferredSystemType?: Maybe<HVSystemType>;
};

/** HVAC Upload Photos */
export type UpdateSectionHvUploadPhotosInput = {
  applicationId: Scalars['ID'];
  currentHeatSourceImages: Array<StoreFileInput>;
  id: Scalars['ID'];
  isPanelUpgrade: Scalars['Boolean'];
  navHistory?: InputMaybe<Array<NavHistoryEntryInput>>;
  panelImages: Array<StoreFileInput>;
  panelUpgradeDesc?: InputMaybe<Scalars['String']>;
  preferredIndoorUnitLocationImages: Array<StoreFileInput>;
  saveAndComeBack?: InputMaybe<Scalars['Boolean']>;
  status: ApplicationSectionStatus;
};

export type UpdateSectionHvUploadPhotosOutput = {
  __typename?: 'UpdateSectionHVUploadPhotosOutput';
  section: SectionHvUploadPhotos;
  validation?: Maybe<ValidationResult>;
};

export type SectionHvUploadPhotos = ApplicationSection & SectionHv & {
  __typename?: 'SectionHVUploadPhotos';
  data: SectionHvUploadPhotosData;
  errors: Array<ApplicationError>;
  id: Scalars['ID'];
  sectionType: ApplicationHVSectionType;
  status: ApplicationSectionStatus;
};

/** HVAC Upload Photos */
export type SectionHvUploadPhotosData = {
  __typename?: 'SectionHVUploadPhotosData';
  currentHeatSourceImages: Array<StoreFile>;
  isPanelUpgrade: Scalars['Boolean'];
  panelImages: Array<StoreFile>;
  panelUpgradeDesc?: Maybe<Scalars['String']>;
  preferredIndoorUnitLocationImages: Array<StoreFile>;
};

export type UpdateServiceProviderUserInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Encrypted'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateServiceProviderUserOutput = {
  __typename?: 'UpdateServiceProviderUserOutput';
  /**
   * Reason the business user is not updated.
   * The business user will not be updated if there is an error.
   */
  errorReason?: Maybe<ErrorReason>;
  id: Scalars['Encrypted'];
};

export type UpdateUserContactDetailsInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
  requestId: Scalars['ID'];
};

export type UpdateUserContactDetailsOutput = {
  __typename?: 'UpdateUserContactDetailsOutput';
  userContactDetails?: Maybe<UserContactDetails>;
};

export type UpsertQuoteInput = {
  quote: QuoteInput;
};

export type QuoteInput = {
  data: QuoteDataInput;
  jobId: Scalars['ID'];
  /** @deprecated job scheduling will happen after quote acceptance. Set service_time on job instead. */
  serviceTime?: InputMaybe<IntervalInput>;
  total?: InputMaybe<Scalars['Float']>;
};

export type QuoteDataInput = {
  lineItems?: InputMaybe<Array<LineItemInput>>;
  quoteDocument?: InputMaybe<QuoteDocumentInput>;
  quoteOptions?: InputMaybe<Array<QuoteOptionInput>>;
};

export type QuoteDocumentInput = {
  fileUrl: Scalars['String'];
  name: Scalars['String'];
};

export type QuoteOptionInput = {
  lineItems?: InputMaybe<Array<LineItemInput>>;
  rank: Scalars['Int'];
  total: Scalars['Float'];
};

export type UpsertQuoteOutput = {
  __typename?: 'UpsertQuoteOutput';
  quote: Quote;
};

export type ErIqbdQualification = {
  __typename?: 'ERIqbdQualification';
  /** required */
  checkEligibility?: Maybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  grossIncome?: Maybe<Scalars['Float']>;
  /** required if checkEligibility */
  grossIncomeConfirmed?: Maybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  houseHoldSize?: Maybe<Scalars['Int']>;
};

export type ErIqbdQualificationInput = {
  /** required */
  checkEligibility?: InputMaybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  grossIncome?: InputMaybe<Scalars['Float']>;
  /** required if checkEligibility */
  grossIncomeConfirmed?: InputMaybe<Scalars['Boolean']>;
  /** required if checkEligibility */
  houseHoldSize?: InputMaybe<Scalars['Int']>;
};

export type EvConsultOperatorProvidedDetails = RequestOperatorProvidedDetails & {
  __typename?: 'EVConsultOperatorProvidedDetails';
  customerIntentToUsePGEPlus?: Maybe<CustomerPGEPlusUseIntentTimeframe>;
  hasMainElectricalPanelConcerns?: Maybe<Scalars['Boolean']>;
  hasRecommendedInstallReferral?: Maybe<YesNoNAResponse>;
  hasRecommendedMainElectricalPanelUpgrade?: Maybe<YesNoNAResponse>;
  recommendedEnergyTrustRebates?: Maybe<EnergyTrustRebates>;
  recommendedInstallerInformation?: Maybe<RecommendedInstallerInformation>;
};

export type EvConsultProvidedDetails = RequestProvidedDetails & {
  __typename?: 'EVConsultProvidedDetails';
  discussionTopics?: Maybe<Array<Maybe<EVConsultDiscussionTopic>>>;
  otherDiscussion?: Maybe<Scalars['String']>;
  preferredDay?: Maybe<Array<Maybe<PreferredDay>>>;
  preferredTime?: Maybe<Array<Maybe<PreferredTime>>>;
};

export type EvSmartChargingEnrollment = {
  __typename?: 'EVSmartChargingEnrollment';
  serialNo: Scalars['String'];
};

export type HvConsultOperatorProvidedDetails = RequestOperatorProvidedDetails & {
  __typename?: 'HVConsultOperatorProvidedDetails';
  customerIntentToUsePGEPlus?: Maybe<CustomerPGEPlusUseIntentTimeframe>;
  hasMainElectricalPanelConcerns?: Maybe<Scalars['Boolean']>;
  hasRecommendedInstallReferral?: Maybe<YesNoNAResponse>;
  hasRecommendedMainElectricalPanelUpgrade?: Maybe<YesNoNAResponse>;
  recommendedEnergyTrustRebates?: Maybe<EnergyTrustRebates>;
  recommendedInstallerInformation?: Maybe<RecommendedInstallerInformation>;
};

export type HvConsultProvidedDetails = RequestProvidedDetails & {
  __typename?: 'HVConsultProvidedDetails';
  consultationService?: Maybe<HVConsultationService>;
  discussionTopics?: Maybe<Array<Maybe<HVConsultDiscussionTopic>>>;
  otherDiscussion?: Maybe<Scalars['String']>;
  preferredDay?: Maybe<Array<Maybe<PreferredDay>>>;
  preferredTime?: Maybe<Array<Maybe<PreferredTime>>>;
};

/** @deprecated use RebatePayment instead */
export type JobPayment = {
  __typename?: 'JobPayment';
  amount: Scalars['Float'];
  status: JobPaymentStatus;
};

/** @deprecated use RebatePaymentStatus instead */
export enum JobPaymentStatus {
  Paid = 'PAID',
  Pending = 'PENDING'
}

/** Supports 'free-form' data. */
export type LineItemDetail = LineItem & {
  __typename?: 'LineItemDetail';
  key: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type LineItemProduct = LineItem & {
  __typename?: 'LineItemProduct';
  amount: Scalars['Float'];
  /** translation key, which isn't necessarily used */
  key: Scalars['String'];
  /** display name of the product */
  name: Scalars['String'];
  productMeta?: Maybe<ProductMeta>;
  /** the product id */
  value: Scalars['String'];
};

export type ProductMeta = {
  __typename?: 'ProductMeta';
  serialNumber?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
};

export type LineItemProgram = LineItem & {
  __typename?: 'LineItemProgram';
  /** identifier for the program */
  key: Scalars['String'];
  /** display name of the program */
  name: Scalars['String'];
  /** possibly a value like 'enrolled' or translation key like 'ENROLLED', something to indicate status */
  value: Scalars['String'];
};

export type LineItemRebate = LineItem & {
  __typename?: 'LineItemRebate';
  amount: Scalars['Float'];
  /** identifier for the rebate */
  key: Scalars['String'];
  /** display name of the rebate */
  name: Scalars['String'];
  rebateItems?: Maybe<RebateItems>;
};

/** Some rebates are broken down into consituent rebate amounts. */
export type RebateItems = {
  __typename?: 'RebateItems';
  evCharger?: Maybe<Scalars['Float']>;
  panelUpgrade?: Maybe<Scalars['Float']>;
};

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export type QuoteHvd = Quote & {
  __typename?: 'QuoteHVD';
  createdAt: Scalars['DateTime'];
  data: QuoteData;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isInstallerToCollectPayment: Scalars['Boolean'];
  jobId: Scalars['ID'];
  /** @deprecated use job.serviceTime */
  serviceTime: Interval;
  status: QuoteStatus;
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
};

export type QuoteHvg = Quote & {
  __typename?: 'QuoteHVG';
  createdAt: Scalars['DateTime'];
  data: QuoteData;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isInstallerToCollectPayment: Scalars['Boolean'];
  jobId: Scalars['ID'];
  /** @deprecated use job.serviceTime */
  serviceTime: Interval;
  status: QuoteStatus;
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
};

export type RequestEv = Request & {
  __typename?: 'RequestEV';
  /** Account contact information */
  accountContact?: Maybe<Contact>;
  accountId?: Maybe<Scalars['String']>;
  assignedToOperationsUserId?: Maybe<Scalars['String']>;
  /** Assigned operations user's name */
  assignedUserName?: Maybe<Scalars['String']>;
  category: RequestCategory;
  /** Attempts to contact customer */
  contactAttempts?: Maybe<Array<Maybe<ContactAttempts>>>;
  createdAt: Scalars['DateTime'];
  createdByCustomerPersonId?: Maybe<Scalars['String']>;
  createdByOperationsUserId?: Maybe<Scalars['String']>;
  /** Request Due By date */
  dueBy: Scalars['DateTime'];
  id: Scalars['ID'];
  isOpened?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  operatorProvidedDetails: EvConsultOperatorProvidedDetails;
  premiseId: Scalars['Encrypted'];
  providedDetails: EvConsultProvidedDetails;
  requestNo: Scalars['String'];
  status: RequestStatus;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Customers contact details */
  userContactDetails: UserContactDetails;
  visibleComments?: Maybe<Array<Maybe<Scalars['String']>>>;
  workComments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RequestHv = Request & {
  __typename?: 'RequestHV';
  /** Account contact information */
  accountContact?: Maybe<Contact>;
  accountId?: Maybe<Scalars['String']>;
  assignedToOperationsUserId?: Maybe<Scalars['String']>;
  /** Assigned operations user's name */
  assignedUserName?: Maybe<Scalars['String']>;
  category: RequestCategory;
  /** Attempts to contact customer */
  contactAttempts?: Maybe<Array<Maybe<ContactAttempts>>>;
  createdAt: Scalars['DateTime'];
  createdByCustomerPersonId?: Maybe<Scalars['String']>;
  createdByOperationsUserId?: Maybe<Scalars['String']>;
  /** Request Due By date */
  dueBy: Scalars['DateTime'];
  id: Scalars['ID'];
  isOpened?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  operatorProvidedDetails: HvConsultOperatorProvidedDetails;
  premiseId: Scalars['Encrypted'];
  providedDetails: HvConsultProvidedDetails;
  requestNo: Scalars['String'];
  status: RequestStatus;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Customers contact details */
  userContactDetails: UserContactDetails;
  visibleComments?: Maybe<Array<Maybe<Scalars['String']>>>;
  workComments?: Maybe<Array<Maybe<Scalars['String']>>>;
};


// alias types to work around codegen renaming issues
export type ApplicationEVSectionType = ApplicationEvSectionType;
export type ApplicationERSectionType = ApplicationErSectionType;
export type ApplicationHVSectionType = ApplicationHvSectionType;
export type EstimateTypeEV = EstimateTypeEv;
export type IneligibleEVEstimateReason = IneligibleEvEstimateReason;
export type OCRDocumentType = OcrDocumentType;
export type OCRFieldNames = OcrFieldNames;
export type HVConsultationService = HvConsultationService;
export type HVConsultDiscussionTopic = HvConsultDiscussionTopic;
export type EVConsultDiscussionTopic = EvConsultDiscussionTopic;
export type SubmitHVConsultInput = SubmitHvConsultInput;
export type SubmitEVConsultInput = SubmitEvConsultInput;
export type CustomerPGEPlusUseIntentTimeframe = CustomerPgePlusUseIntentTimeframe;
export type YesNoNAResponse = YesNoNaResponse;
export type HVPreference = HvPreference;
export type HVSystemType = HvSystemType;
export type UpdateSectionHVSystemInfoOutput = UpdateSectionHvSystemInfoOutput;
